<div class="chat border border-[#bdbdbd] shadow-global " style="background-color: white;" (paste)="onPaste($event)">
  <div class="initial-screen" *ngIf="!attendance">

    <empty-records-component [message]="'Você ainda não tem Chats ativos!'"
      [subMessage]="'Quando uma nova conversa for iniciada, ela aparecerá aqui'">
    </empty-records-component>

  </div>
  <div class="ctn-chat" *ngIf="attendance">
    <div class="user">
      <div class="flex gap-3">
        <img [src]="getContactImage(attendance?.contact)" class="img-user" alt="Imagem do contato"
          (error)="onImageError($event)">
        <div class="flex flex-col">
          <div class="h5">{{attendance?.contact?.name}}</div>
          <div class="h6">{{attendance?.department?.name}} {{ attendance?.user ? ' | ' + attendance?.user?.name : '' }}
          </div>
        </div>
        <div>
          <ng-container *ngIf="attendance?.expiredAt && attendance?.channel?.type === 'CLOUD_API'">
            <ng-container *ngIf="formatExpireIn(attendance.expiredAt) as dateInfo">
              <span class="px-[10px] py-[5px] rounded-[5px] mr-[10px] flex-wrap pulse" [ngClass]="{
                  'bg-red-300 text-red-700': dateInfo.isExpired,
                  'bg-green-300 text-green-700': !dateInfo.isExpired
                }">
                {{ dateInfo.text }}
              </span>
            </ng-container>
          </ng-container>

        </div>
      </div>
      <button mat-button class="btn-close" *ngIf="!sidebarRight" (click)="toggleSidebar()">
        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.0672 12.8082C14.1253 12.8662 14.1713 12.9352 14.2027 13.011C14.2342 13.0869 14.2504 13.1682 14.2504 13.2503C14.2504 13.3325 14.2342 13.4138 14.2027 13.4897C14.1713 13.5655 14.1253 13.6345 14.0672 13.6925C14.0091 13.7506 13.9402 13.7967 13.8643 13.8281C13.7884 13.8595 13.7071 13.8757 13.625 13.8757C13.5429 13.8757 13.4616 13.8595 13.3857 13.8281C13.3098 13.7967 13.2409 13.7506 13.1828 13.6925L6.93282 7.44254C6.87471 7.38449 6.82861 7.31556 6.79715 7.23969C6.7657 7.16381 6.74951 7.08248 6.74951 7.00035C6.74951 6.91821 6.7657 6.83688 6.79715 6.76101C6.82861 6.68514 6.87471 6.61621 6.93282 6.55816L13.1828 0.30816C13.3001 0.190885 13.4592 0.125 13.625 0.125C13.7909 0.125 13.9499 0.190885 14.0672 0.30816C14.1845 0.425435 14.2504 0.584495 14.2504 0.750347C14.2504 0.9162 14.1845 1.07526 14.0672 1.19253L8.2586 7.00035L14.0672 12.8082ZM2.0086 7.00035L7.81719 1.19253C7.93447 1.07526 8.00035 0.9162 8.00035 0.750347C8.00035 0.584495 7.93447 0.425435 7.81719 0.30816C7.69992 0.190885 7.54086 0.125 7.375 0.125C7.20915 0.125 7.05009 0.190885 6.93282 0.30816L0.682816 6.55816C0.624706 6.61621 0.578606 6.68514 0.547154 6.76101C0.515701 6.83688 0.499512 6.91821 0.499512 7.00035C0.499512 7.08248 0.515701 7.16381 0.547154 7.23969C0.578606 7.31556 0.624706 7.38449 0.682816 7.44254L6.93282 13.6925C6.99088 13.7506 7.05982 13.7967 7.13569 13.8281C7.21156 13.8595 7.29288 13.8757 7.375 13.8757C7.45713 13.8757 7.53844 13.8595 7.61431 13.8281C7.69018 13.7967 7.75912 13.7506 7.81719 13.6925C7.87526 13.6345 7.92132 13.5655 7.95275 13.4897C7.98418 13.4138 8.00035 13.3325 8.00035 13.2503C8.00035 13.1682 7.98418 13.0869 7.95275 13.011C7.92132 12.9352 7.87526 12.8662 7.81719 12.8082L2.0086 7.00035Z"
            fill="black" />
        </svg>
      </button>
      <button mat-button class="btn-close" *ngIf="sidebarRight" (click)="toggleSidebar()">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.0675 10.4425L4.81753 16.6925C4.70026 16.8098 4.5412 16.8757 4.37535 16.8757C4.2095 16.8757 4.05044 16.8098 3.93316 16.6925C3.81588 16.5753 3.75 16.4162 3.75 16.2503C3.75 16.0845 3.81588 15.9254 3.93316 15.8082L9.74175 10.0003L3.93316 4.19253C3.81588 4.07526 3.75 3.9162 3.75 3.75035C3.75 3.5845 3.81588 3.42544 3.93316 3.30816C4.05044 3.19088 4.2095 3.125 4.37535 3.125C4.5412 3.125 4.70026 3.19088 4.81753 3.30816L11.0675 9.55816C11.1256 9.61621 11.1717 9.68514 11.2032 9.76101C11.2347 9.83688 11.2508 9.91821 11.2508 10.0003C11.2508 10.0825 11.2347 10.1638 11.2032 10.2397C11.1717 10.3156 11.1256 10.3845 11.0675 10.4425ZM17.3175 9.55816L11.0675 3.30816C10.9503 3.19088 10.7912 3.125 10.6253 3.125C10.4595 3.125 10.3004 3.19088 10.1832 3.30816C10.0659 3.42544 10 3.5845 10 3.75035C10 3.9162 10.0659 4.07526 10.1832 4.19253L15.9918 10.0003L10.1832 15.8082C10.0659 15.9254 10 16.0845 10 16.2503C10 16.4162 10.0659 16.5753 10.1832 16.6925C10.3004 16.8098 10.4595 16.8757 10.6253 16.8757C10.7912 16.8757 10.9503 16.8098 11.0675 16.6925L17.3175 10.4425C17.3756 10.3845 17.4217 10.3156 17.4532 10.2397C17.4847 10.1638 17.5008 10.0825 17.5008 10.0003C17.5008 9.91821 17.4847 9.83688 17.4532 9.76101C17.4217 9.68514 17.3756 9.61621 17.3175 9.55816Z"
            fill="black" />
        </svg>
      </button>

    </div>

    <chat-messages-component [attendance]="attendance" [messages]="messages"></chat-messages-component>

    <div class="ctn-act" *ngIf="attendance?.status !== 'CLOSED'">
      <div id="row-ctn" class="d-flex">
        <div id="row-intern" class="row">
          <div class="col-12">
            <mat-menu id="menu-items" #mediaMenu="matMenu">
              <button mat-menu-item (click)="sendMedia('video')">
                <mat-icon style="color: #4213F6;">video_library</mat-icon> +Video
              </button>
              <button mat-menu-item (click)="sendMedia('document')">
                <mat-icon style="color: #4213F6;">description</mat-icon> +Documento
              </button>
              <button mat-menu-item (click)="sendMedia('image')">
                <mat-icon style="color: #4213F6;">image</mat-icon> +Imagem
              </button>
              <button mat-menu-item (click)="sendMedia('audio')">
                <mat-icon style="color: #4213F6;">headset</mat-icon> +Áudio
              </button>
            </mat-menu>
          </div>
          <form *ngIf="isExpiredisConversation()" [formGroup]="formGroup">
            <div *ngIf="isRecording"
              class="flex items-center justify-center gap-4 border border-gray-400 rounded-lg w-full">
              <button *ngIf="isRecording" class="icon-chat" (click)="toggleRecording(attendance._id)">
                <mat-icon class="text-red-600">stop</mat-icon>
              </button>
              <p *ngIf="isRecording">{{ getFormattedTime() }}</p>
              <button *ngIf="isRecording" class="icon-chat" (click)="cancelRecording()">
                <mat-icon class="text-red-600">delete</mat-icon>
              </button>
            </div>
            <div class="w-full flex items-center justify-end border border-[#bdbdbd] rounded-lg" *ngIf="isLoading">
              <div class="flex m-auto flex-col" *ngIf="isLoading && !audioUrl">
                <mat-spinner class="m-auto" [diameter]="25" [color]="'primary'" mode="indeterminate">
                </mat-spinner>
                <small class="m-auto text-[10px] font-bold">Carregando áudio...</small>
              </div>

              <div *ngIf="audioUrl" class="w-auto flex justify-center items-center gap-1">
                <audio class="h-10 ml-2" [src]="audioUrl" controls></audio>
                <button *ngIf="!isRecording" class="icon-chat mt-1" (click)="cancelPreview(attendance._id)">
                  <mat-icon class="text-red-600">delete</mat-icon>
                </button>
              </div>
            </div>
            <button *ngIf="audioUrl" class="cursor-pointer ml-2 hover:bg-[#f2f2f3] hover:fill-white hover:rounded-full"
              (click)="sendAudio(attendance)">
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="40" height="40" rx="8" fill="#4213F6" />
                <path
                  d="M11.51 14.03L19.02 17.25L11.5 16.25L11.51 14.03ZM19.01 22.75L11.5 25.97V23.75L19.01 22.75ZM9.51 11L9.5 18L24.5 20L9.5 22L9.51 29L30.5 20L9.51 11Z"
                  fill="white" fill-opacity="0.960784" />
              </svg>
            </button>
            <div *ngIf="!isRecording && !audioUrl && !isLoading"
              [ngClass]="{'input-message' : true, 'input-message-focused': isInputFocused}">
              <button class="icon-chat" [matMenuTriggerFor]="mediaMenu">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14.9997 13.3327H5.41634C3.57467 13.3327 2.08301 11.841 2.08301 9.99935C2.08301 8.15768 3.57467 6.66602 5.41634 6.66602H15.833C16.983 6.66602 17.9163 7.59935 17.9163 8.74935C17.9163 9.89935 16.983 10.8327 15.833 10.8327H7.08301C6.62467 10.8327 6.24967 10.4577 6.24967 9.99935C6.24967 9.54102 6.62467 9.16602 7.08301 9.16602H14.9997V7.91602H7.08301C5.93301 7.91602 4.99967 8.84935 4.99967 9.99935C4.99967 11.1493 5.93301 12.0827 7.08301 12.0827H15.833C17.6747 12.0827 19.1663 10.591 19.1663 8.74935C19.1663 6.90768 17.6747 5.41602 15.833 5.41602H5.41634C2.88301 5.41602 0.833008 7.46602 0.833008 9.99935C0.833008 12.5327 2.88301 14.5827 5.41634 14.5827H14.9997V13.3327Z"
                    fill="#667085" />
                </svg>
              </button>
              <button class="icon-chat" (click)="toogleContainerEmoji()">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4.99967 8.33333C4.99967 7.64167 5.55801 7.08333 6.24967 7.08333C6.94134 7.08333 7.49967 7.64167 7.49967 8.33333C7.49967 9.025 6.94134 9.58333 6.24967 9.58333C5.55801 9.58333 4.99967 9.025 4.99967 8.33333ZM9.16634 15C11.108 15 12.758 13.7833 13.4247 12.0833H4.90801C5.57467 13.7833 7.22467 15 9.16634 15ZM12.083 9.58333C12.7747 9.58333 13.333 9.025 13.333 8.33333C13.333 7.64167 12.7747 7.08333 12.083 7.08333C11.3913 7.08333 10.833 7.64167 10.833 8.33333C10.833 9.025 11.3913 9.58333 12.083 9.58333ZM17.4997 1.25H15.833V2.91667H14.1663V4.58333H15.833V6.25H17.4997V4.58333H19.1663V2.91667H17.4997V1.25ZM15.833 10.4167C15.833 14.1 12.8497 17.0833 9.16634 17.0833C5.48301 17.0833 2.49967 14.1 2.49967 10.4167C2.49967 6.73333 5.48301 3.75 9.16634 3.75C10.383 3.75 11.5163 4.08333 12.4997 4.65V2.78333C11.4747 2.33333 10.3497 2.08333 9.15801 2.08333C4.55801 2.08333 0.833008 5.81667 0.833008 10.4167C0.833008 15.0167 4.55801 18.75 9.15801 18.75C13.7663 18.75 17.4997 15.0167 17.4997 10.4167C17.4997 9.54167 17.358 8.70833 17.108 7.91667H15.333C15.6497 8.69167 15.833 9.53333 15.833 10.4167Z"
                    fill="#667085" />
                </svg>
              </button>
              <button class="icon-chat" (click)="openModalResponse()">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M15.667 0.666016H2.33366C1.41699 0.666016 0.675326 1.41602 0.675326 2.33268L0.666992 17.3327L4.00033 13.9993H15.667C16.5837 13.9993 17.3337 13.2493 17.3337 12.3327V2.33268C17.3337 1.41602 16.5837 0.666016 15.667 0.666016ZM15.667 12.3327H3.30866L2.33366 13.3077V2.33268H15.667V12.3327ZM4.00033 8.99935H5.66699V10.666H4.00033V8.99935ZM4.00033 6.49935H5.66699V8.16602H4.00033V6.49935ZM4.00033 3.99935H5.66699V5.66602H4.00033V3.99935ZM7.33366 8.99935H11.5003V10.666H7.33366V8.99935ZM7.33366 6.49935H14.0003V8.16602H7.33366V6.49935ZM7.33366 3.99935H14.0003V5.66602H7.33366V3.99935Z"
                    fill="#667085" />
                </svg>
              </button>

              <textarea class="input-field" formControlName="message" placeholder="Digitar..."
                (keydown.enter)="enterEventPress($event)" (focus)="focusInput()" (blur)="blurInput()" cols="1" rows="1"
                style="resize: none;"></textarea>
              <button class="icon-chat" (click)="toggleRecording(attendance._id)" *ngIf="!audioUrl">
                <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12.8337 8.33398V10.0007C12.8337 11.5477 12.2191 13.0315 11.1251 14.1254C10.0312 15.2194 8.54742 15.834 7.00033 15.834M7.00033 15.834C5.45323 15.834 3.9695 15.2194 2.87554 14.1254C1.78157 13.0315 1.16699 11.5477 1.16699 10.0007V8.33398M7.00033 15.834V19.1673M3.66699 19.1673H10.3337M7.00033 0.833984C6.33728 0.833984 5.7014 1.09738 5.23256 1.56622C4.76372 2.03506 4.50033 2.67094 4.50033 3.33398V10.0007C4.50033 10.6637 4.76372 11.2996 5.23256 11.7684C5.7014 12.2373 6.33728 12.5006 7.00033 12.5006C7.66337 12.5006 8.29925 12.2373 8.76809 11.7684C9.23693 11.2996 9.50033 10.6637 9.50033 10.0007V3.33398C9.50033 2.67094 9.23693 2.03506 8.76809 1.56622C8.29925 1.09738 7.66337 0.833984 7.00033 0.833984Z"
                    stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </button>
            </div>
            <div *ngIf="!isRecording && !audioUrl" id="btn-send">
              <button class="btn-add" (click)="sendText()">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="40" height="40" rx="8" fill="#4213F6" />
                  <path
                    d="M11.51 14.03L19.02 17.25L11.5 16.25L11.51 14.03ZM19.01 22.75L11.5 25.97V23.75L19.01 22.75ZM9.51 11L9.5 18L24.5 20L9.5 22L9.51 29L30.5 20L9.51 11Z"
                    fill="white" fill-opacity="0.960784" />
                </svg>
              </button>
            </div>
          </form>

          <div>
            <div *ngIf="attendance?.channel?.type === 'CLOUD_API' && !isExpiredisConversation()"
              class="rounded-lg flex items-center justify-center bg-slate-200 gap-2">
              <button class="p-2 font-bold pointer-events-none">
                Envie um template e aguarde a resposta para interação.
              </button>
            </div>
            <div class="chatbottom-main flex  gap-2">
              <button (click)="selectTemplate()" *ngIf="attendance?.channel?.type === 'CLOUD_API'"
                class="h-[45px] flex justify-center items-center rounded-lg border-1 border-[#BDB DBD] text-black font-bold hover:bg-gray-100 w-full">
                Template
              </button>

              <button *ngIf="isExpiredisConversation()" (click)="openAssignedUserTransferModal()"
                class="h-[45px] flex justify-center items-center rounded-lg border-1 border-[#BDBDBD] text-black font-bold hover:bg-gray-100 w-full">
                Transferir
              </button>
              <button
                (click)="attendance.status === statusPaused ? updateStatusAttendance(statusInProgress) : updateStatusAttendance(statusPaused)"
                class="h-[45px] flex justify-center items-center rounded-lg border-1 border-[#BDBDBD] text-black font-bold hover:bg-gray-100 w-full">
                {{ attendance.status === statusPaused ? 'Continuar' : 'Pausar' }}
              </button>
              <button (click)="closeAttendance()"
                class="h-[45px] flex justify-center items-center rounded-lg border-1 border-[#BDBDBD] text-black font-bold hover:bg-gray-100 w-full">
                Encerrar
              </button>
            </div>

          </div>
          <div class="relative" id="emoji-container">
            @if (showContainerEmoji) {
            <emoji-mart (emojiSelect)="addEmoji($event)" [i18n]="translator" [darkMode]="false"
              class="absolute bottom-28 md:bottom-28 lg:bottom-28 z-50">
            </emoji-mart>
            }
          </div>
          <div class="col-md-3">

          </div>
        </div>
      </div>
    </div>

    <div class="inputs" [hidden]="true">
      <input type="file" class="form-control inputfile" id="inputImagefile" (change)="onSelectMedia('image', $event)"
        accept=".jpeg, .jpg, .png" #inputImagefile>
      <input type="file" class="form-control inputfile" id="inputVideofile" (change)="onSelectMedia('video', $event)"
        accept=".3gp, .mp4" #inputVideofile>
      <input type="file" class="form-control inputfile" id="inputDocfile" (change)="onSelectMedia('document', $event)"
        accept=".txt, .xls, .xlsx, .doc, .docx, .ppt, .pptx, .pdf" #inputDocfile>
      <input type="file" class="form-control inputfile" id="inputAudiofile" (change)="onSelectMedia('audio', $event)"
        accept=".acc, .amr, .mp3, .m4a, .ogg" #inputAudiofile>
    </div>
  </div>

  <div style="display: none">
    <a (click)="updateChatFromSocket()" #btnUpdateChatFromSocket>Update chat from socket</a>
    <a (click)="updateMessagesFromSocket()" #btnUpdateMessagesFromSocket>Update messages from socket</a>
  </div>

</div>