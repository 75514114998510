import { createAction, props } from '@ngrx/store';
import { Attendance, Company, Message, Note, Pager, SearchParams, Tag, User } from 'lib-trend-core';
import { CountState, PagerParamsState } from './app.state';

// app state
export const clearFullState = createAction('[Clear] Full state', props<{ clear: boolean }>());

export const getLoadInit = createAction('[Init] Load initial', props<{ idUser: string, idCompany: string }>());
export const setLoadInit = createAction('[Init] Set initial', props<{ user: User, company: Company }>());

export const getAttendances = createAction(
  "[Attendance] Get Attendances",
  props<{ page: number, perPage: number, search?: string, params?: Partial<SearchParams> }>()
);

export const getAttendancesSuccess = createAction('[Attendance] Get Attendance Success', props<{ attendances: Pager<Attendance> }>());

export const getAttendance = createAction('[Attendance] Get Attendance', props<{ idAttendance: string }>());

export const getCounts = createAction('[Attendance] Get Counts');

export const getMessages = createAction('[Message] Get Messages', props<{ idAttendance: string }>());
export const getNotes = createAction('[Note] Get notes', props<{ idAttendance: string }>());
export const getTags = createAction('[Tag] Get tags');

export const setAttendance = createAction('[Attendance] Set Attendance', props<{ attendance: Attendance }>());
export const setNotes = createAction('[Attendance Note] Get Note of Attendance', props<{ notes: Array<Note> }>());
export const setMessages = createAction('[Attendance Message] Get Messages of Attendance', props<{ messages: Array<Message> }>());
export const setTags = createAction('[Tag] Set Tags of Attendance', props<{ tags: Array<Tag> }>());
export const setCounts = createAction('[Tag] Set Counts of Attendance', props<{ counts: Array<CountState> }>());

export const setPagerParams = createAction('[Attendance PagerParams] Set PagerParams of Attendance', props<{ pagerParams: PagerParamsState }>());

export const setFailure = createAction('[Failure] Error', props<{ error: string }>());

export const openCloseSidebarRight = createAction('[Sidebar] Open/Close', props<{ sidebarRight: boolean }>());
export const openCloseContactInfo = createAction('[ContactInfo] Open/Close', props<{ contactInfo: boolean }>());

