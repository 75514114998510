<div class="bg-[#8dc1ce] rounded-lg p-1 border-l-4 border-green-700 flex flex-col ml-[-5px]" *ngIf="!!message.reply">
  <p class="text-green-900 font-bold">
    {{ formatWhatsappMessage(message.reply?.user?.name || attendance?.contact?.name || message.reply.to) }}
  </p>
  <a href="#" (click)="scrollToMessage($event, message.reply._id)" tracked>
    <div class="flex flex-col">
      <ng-container *ngIf="!isMedia(message.reply.contentType); else mediaMessage">
        <p class="mb-3 mt-2 text-[#e5f4f7] flex items-center"
        [ngClass]="{'message-text-automated': message.automated}"
        [innerHTML]="formatWhatsappMessage(message.reply.content)">
      </p>
      </ng-container>
      <ng-template #mediaMessage>
        <ng-container *ngIf="isMedia(message.reply.contentType)">
          <span class="mb-3 mt-2 text-[#e5f4f7] flex items-center">
            <mat-icon class="mr-2">
              {{ getIconForContentType(message.reply.contentType) }}
            </mat-icon>
            {{ getAttachmentMessage(message.reply.contentType) }}
          </span>
        </ng-container>
      </ng-template>
    </div>
  </a>
</div>
