import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-trend-core';
import { AttendanceContactCardComponent } from './attendance-contact-card.component';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    AttendanceContactCardComponent,
  ],
  exports: [
    AttendanceContactCardComponent,
  ],
  providers: [],
})

export class AttendanceContactCardModule { }
