import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-trend-core';
import { ContactInfoComponent } from './contact-info/contact-info.component';
import { ContactListComponent } from './contact-list/contact-list.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    ContactListComponent,
    ContactInfoComponent
  ],
  exports: [
    ContactListComponent,
    ContactInfoComponent
  ]
})
export class ContacttModule { }
