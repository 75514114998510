import { Component, Injector, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractComponent, Attendance } from 'lib-trend-core';

@Component({
  selector: 'attendance-contact-card-component',
  templateUrl: 'attendance-contact-card.component.html',
  styleUrls: ['attendance-contact-card.component.scss']
})
export class AttendanceContactCardComponent extends AbstractComponent implements OnInit, OnChanges {

  @Input() item: Attendance;
  @Input() status: string;
  @Input() selected: boolean = false;

  unreadCount: number = 0;

  constructor(
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selected'] && changes['selected'].currentValue) {
      this.unreadCount = 0;
    }
    if (changes['item'] && changes['item'].currentValue) {
      this.unreadCount = this.item?.countUnreadMessages ?? 0;
    }
  }

}
