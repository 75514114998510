import { Component, EventEmitter, Inject, Injector, OnDestroy, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TemplateMessageObject, TrendCloudAPIService } from '@trendbuild/trend-cloud-api';
import { AbstractComponent, Attendance, AttendanceService, Channel, ChannelService, Contact, ContactEditComponent, ContactService, MessageService, WaTemplateResponse } from 'lib-trend-core';
import { TemplateMessage } from '../../wa-template/wa-template-message/wa-template-message.component';

@Component({
  selector: 'app-chat-select-template',
  templateUrl: './chat-select-template.component.html',
  styleUrl: './chat-select-template.component.scss'
})
export class ChatSelectTemplateComponent extends AbstractComponent implements OnInit, OnDestroy {
  
  step = 1;
  selectedContact: Contact = null;
  contacts = new Array<any>();

  selectedTemplate: WaTemplateResponse;
  selectedChannel: Channel;
  templateMessage: TemplateMessage;

  previewHeader: any;

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<ContactEditComponent>,
    public contactService: ContactService,
    public channelService: ChannelService,
    public messageService: MessageService,
    public attendanceService: AttendanceService,
    public trendCloudAPIService: TrendCloudAPIService,
    @Inject(MAT_DIALOG_DATA) public attendance: Attendance) {

    super(injector);
    this.selectedChannel = attendance.channel;
    this.selectedContact = attendance.contact;
  }

  ngOnInit(): void {
  }

  onMidiaHeaderChange(midiaHeader: string) {
    this.templateMessage.midiaHeader = midiaHeader;
  }

  onSelectTemplate(template: WaTemplateResponse) {
    this.selectedTemplate = template;
    this.nextStep();
 }
 
  setTemplateMessage(templateMessage: TemplateMessage) {
    this.templateMessage = templateMessage;
    this.previewHeader = templateMessage.previewHeader;
  }

  previousStep() {
    this.step--;
  }

  nextStep() {
    this.step++;
  }

  close() {
    this.dialogRef.close();
  }

selectTemplateMessage() {
  const templateVariables = this.selectedTemplate?.bodyComponent?.text.match(/{{\d+}}/g) || [];

  if (templateVariables.length > 0 && (this.templateMessage.params.length !== templateVariables.length)) {
    this.alertService.error('Por favor, preencha todas as variáveis necessárias.');
    return;
  }

  console.log(this.templateMessage.midiaHeader);

  const requiresImage = !!this.previewHeader;
  if (requiresImage) {
    if (!this.templateMessage.midiaHeader) {
      this.alertService.error('Por favor, insira a imagem requerida para o template.');
      return;
    }
  }

  this.dialogRef.close({
    template: <TemplateMessageObject>{ ...this.selectedTemplate },
    params: this.templateMessage.params,
    link: this.templateMessage.midiaHeader,
    previewText: this.templateMessage.previewText
  });
}

}
