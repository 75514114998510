import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AbstractComponent, Attendance, Contact, ContactEditComponent, ContactGroup, ContactGroupService, CpfTypePipe } from 'lib-trend-core';
import { Observable } from 'rxjs';
import { getAttendance, openCloseContactInfo } from '../../../state/actions';
import { AppState, } from '../../../state/app.state';
import { attendanceSelector, openClosecontactInfoSelector } from '../../../state/selectors';

@Component({
  selector: 'contact-info-component',
  templateUrl: 'contact-info.component.html',
  styleUrls: ['contact-info.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        height: '*',
        opacity: 1
      })),
      state('out', style({
        height: '0',
        opacity: 0
      })),
      transition('in => out', [
        animate('300ms ease-in-out')
      ]),
      transition('out => in', [
        animate('300ms ease-in-out')
      ])
    ])
  ],
})
export class ContactInfoComponent extends AbstractComponent implements OnInit {

  contactInfoState: string = 'out';
  contactInfo: boolean = false;
  currentUser: any
  readonly openCloseContactInfo: Observable<boolean> = this.store.select(openClosecontactInfoSelector);
  readonly attendance$: Observable<Attendance> = this.store.select(attendanceSelector);

  attendance: Attendance;

  listContactGroups: Array<ContactGroup> = new Array<ContactGroup>();

  constructor(
    injector: Injector,
    private store: Store<AppState>,
    private matDialog: MatDialog,
    private contactGroupService: ContactGroupService,
  ) {
    super(injector);

  }

  edit(contact: Contact): void {
    const dialogRef = this.matDialog.open(ContactEditComponent, {
      width: '700px',
      data: contact,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.store.dispatch(openCloseContactInfo({ contactInfo: this.contactInfo }));
      this.store.dispatch(getAttendance({ idAttendance: this.attendance._id }));
    });
  }

  ngOnInit(): void {
    this.currentUser = this.getCurrentUserUser();
    this.getListGroups();
    this.openCloseContactInfo.subscribe(isOpen => {
      this.contactInfoState = isOpen ? 'in' : 'out';
    });
    this.attendance$.subscribe(attendance => this.attendance = attendance);
  }
  toggleContactInfo() {
    this.contactInfo = !this.contactInfo;
    this.store.dispatch(openCloseContactInfo({ contactInfo: this.contactInfo }));
  }

  getFormatAddres(attendance: Attendance): string {
    return `${attendance?.contact?.city ?? '-'} - ${attendance?.contact?.state ?? '-'}`;
  }

  getFormatTin(tin: string): string {
    let cpfPipe: CpfTypePipe = new CpfTypePipe();
    return cpfPipe.transform(tin);
  }

  getFormatGender(gender: string): string {
    return gender === 'MALE' ? 'Masculino' : gender === 'FEMALE' ? 'Feminino' : 'Outro';
  }

  getGroups(ids: Array<unknown>): string {
    if (!this.listContactGroups || this.listContactGroups.length === 0 || !ids) {
      return '-';
    }

    const groupNames = this.listContactGroups
      .filter((group) => ids.includes(group._id))
      .map(group => group.name);

    return groupNames.length > 0 ? groupNames.join(', ') : '-';
  }

  private getListGroups() {
    this.contactGroupService.getList().subscribe({
      next: (list: Array<ContactGroup>) => {
        this.listContactGroups = list;
      },
      error: (err) => this.alertService.error(err.error),
    });
  }
}
