import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Store } from '@ngrx/store';
import { AbstractComponent, Attendance, AttendanceService, AttendanceStatusEnum, Tag, TagService, TagTypeEnum } from 'lib-trend-core';
import { Observable } from 'rxjs';
import { getAttendance } from '../../state/actions';
import { AppState } from '../../state/app.state';
import { attendanceSelector, tagsSelector } from '../../state/selectors';

@Component({
  selector: 'service-tag-attendance',
  templateUrl: './tag-attendance.component.html',
  styleUrls: ['./tag-attendance.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TagAttendanceComponent extends AbstractComponent implements OnInit, OnChanges {

  readonly attendance$: Observable<Attendance> = this.store.select(attendanceSelector);
  readonly tags$: Observable<Array<Tag>> = this.store.select(tagsSelector);

  @Input() categoryTag: TagTypeEnum;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  tags: Array<Tag> = new Array<Tag>();
  tagSelectedList: Tag[] = [];
  attendance: Attendance;
  showAutocomplete = false;

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

  constructor(
    injector: Injector,
    private store: Store<AppState>,
    public tagService: TagService,
    public attendanceService: AttendanceService,
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<any> {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['categoryTag'] && changes['categoryTag'].currentValue && this.tags?.length === 0) {
      this.attendance$.subscribe(attendance => this.attendance = attendance);
      this.tags$.subscribe(tags => this.tags = tags);
    }
  }

  public get tagsByCategory() {
    return this.tags?.filter(tag => tag.category === this.categoryTag);
  }

  getFilteredList() {
    return this.attendance?.tags?.filter(tag => tag.category === this.categoryTag);
  }

  toggleAutocomplete() {
    this.showAutocomplete = !this.showAutocomplete;
    if (this.showAutocomplete) {
      setTimeout(() => this.tagInput.nativeElement.focus(), 0);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.tagInput.nativeElement.value = '';
    this.addRemoveTag(event.option.value._id);
    this.showAutocomplete = false;
  }

  remove(tag: Tag): void {
    this.addRemoveTag(tag._id);
  }

  getTitle() {
    switch (this.categoryTag) {
      case TagTypeEnum.CONTACT:
        return 'Contato';
      case TagTypeEnum.CAMPAIGN:
        return 'Campanha';
      case TagTypeEnum.INTEREST:
        return 'Interesse';
      case TagTypeEnum.PROTOCOL:
        return 'Protocolo';
      default:
        return 'Etiquetas';
    }
  }

  addRemoveTag(idTag: string) {
    if (this.attendance.status === AttendanceStatusEnum.CLOSED) {
      this.alertService.info('Não é possível adicionar ou remover tags para atendimentos com status fechado');
      return;
    }

    this.attendanceService.addRemoveTag({ idTag: idTag, idAttendance: this.attendance._id }).subscribe({
      next: (value) => this.store.dispatch(getAttendance({ idAttendance: value._id })),
      error: (err) => this.alertService.error(err.error.message),
      complete: () => { },
    });
  }

  private _filter(value: string): Tag[] {
    const filterValue = value.toLowerCase();
    return this.tags?.filter(tag => tag.title.toLowerCase().includes(filterValue));
  }

}
