import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TemplateRequest, TrendCloudAPIService } from '@trendbuild/trend-cloud-api';
import { AbstractComponent, Attendance, AttendanceService, Channel, ChannelService, Contact, ContactEditComponent, MessageService, Pager, WaTemplateResponse } from 'lib-trend-core';
import { Observable } from 'rxjs';
import { AppState } from '../../../state/app.state';
import { attendanceSelector } from '../../../state/selectors';
export interface TemplateMessage {
  previewText: string;
  params: any[];
}

@Component({
  selector: 'wa-template',
  templateUrl: './wa-template.component.html',
  styleUrls: ['./wa-template.component.scss']
})
export class WaTemplateComponent extends AbstractComponent implements OnInit {
  pager: Pager<WaTemplateResponse> = new Pager<WaTemplateResponse>({ perPage: 4 });

  @Input() set channel(channel: Channel) {
    this.selectedChannel = channel;
    this.listWaTemplates();
  }

  @Output() onSelectTemplate = new EventEmitter<WaTemplateResponse>();
  @Output() templateParams = new EventEmitter<TemplateMessage>();

  previewHeaderData: string;

  selectedTemplate: WaTemplateResponse | null = null;
  override templateVariableList: any[] = [];
  waTemplates: WaTemplateResponse[] = [];
  selectedContact: Contact = null;
  selectedChannel: Channel;
  channels: Channel[];
  form: FormGroup;
  previewText: string;
  templateMessage: TemplateMessage;

  attendance: Attendance;
  readonly attendance$: Observable<Attendance> = this.store.select(attendanceSelector);

  private trendCloudAPIService: TrendCloudAPIService = new TrendCloudAPIService();

  constructor(

    injector: Injector,
    public channelService: ChannelService,
    public dialogRef: MatDialogRef<ContactEditComponent>,
    public override formBuilder: FormBuilder,
    public attendanceService: AttendanceService,
    private store: Store<AppState>,
    public messageService: MessageService
  ) {
    super(injector);
    this.form = this.formBuilder.group({});
    this.attendance$ = this.store.select(attendanceSelector);
  }

  ngOnInit(): void {
    this.pager = {
      page: 1,
      perPage: 4,
      previousPage: 0,
      total: 0,
      list: []
    };

    const pager: Pager<Channel> = {
      page: 1,
      perPage: 50,
      previousPage: 0,
      total: 0,
      list: []
    };

    this.channelService.getAll(pager.page, pager.perPage).subscribe(channels => {
      this.channels = channels.list;
    });

    this.attendance$.subscribe(attendance => {
      if (!attendance) return;
      this.attendance = attendance;
    });
  }

  onTemplateParamsReceived(data: any): void {
    this.previewHeaderData = data.previewHeader;
  }

  private async listWaTemplates(): Promise<void> {
    if (!this.selectedChannel) return;

    const templateRequest: TemplateRequest = {
      token: this.selectedChannel.metadata.token,
      whatsAppBusinessAccountID: +this.selectedChannel.metadata.whatsAppBusinessAccountID,
      phoneNumberID: +this.selectedChannel.metadata.phoneNumberID,
      fields: [],
      limit: 1000,
      status: ''
    };

    const response = await this.trendCloudAPIService.getTemplates(templateRequest);
    const allTemplates = (response as any).data || [];

    this.pager.total = (response as any).total || allTemplates.length;

    const start = (this.pager.page - 1) * this.pager.perPage;
    const end = start + this.pager.perPage;
    this.waTemplates = allTemplates.slice(start, end);

    this.waTemplates.forEach(wat => {
      wat.headerComponent = wat.components.find(c => c.type === 'HEADER');
      wat.bodyComponent = wat.components.find(c => c.type === 'BODY');
      wat.footerComponent = wat.components.find(c => c.type === 'FOOTER');
      wat.buttonsComponent = wat.components.find(c => c.type === 'BUTTONS');
    });
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.listWaTemplates();
  }

  showTemplateDetails(template: WaTemplateResponse) {
    this.extractTemplateVariables();
    this.onSelectTemplate.emit(template);
  }

  extractTemplateVariables() {
    if (!this.selectedTemplate) return;

    this.previewText = { ...this.selectedTemplate.bodyComponent }.text;

    this.templateVariableList = [];
    [1, 2, 3, 4].forEach((value, idx) => {
      if (this.previewText.includes(`{{${value}}}`)) {
        this.templateVariableList.push(`{{${value}}}`);
      }
    });

    this.setupForm();
  }

  setupForm() {
    this.form = this.formBuilder.group({});

    if (this.templateVariableList) {
      this.templateVariableList.forEach((variable, index) => {
        this.form.addControl(`var${index + 1}`, new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(30)]));

        this.form.get(`var${index + 1}`).valueChanges.subscribe(data => {
          const originalText = this.selectedTemplate.bodyComponent.text;
          this.previewText = originalText.replace(variable, data);
        });
      });
    }
  }

  selectVarContactName(index: number) {
    this.form.get(`var${index}`).setValue('Nome do Contato');
  }

  selectVarLinkChannel(index: number) {
    this.form.get(`var${index}`).setValue('Link canal Whatsapp');
  }

  selectVarOperatorName(index: number) {
    this.form.get(`var${index}`).setValue('Nome atendente');
  }

  selectVarDepartmentName(index: number) {
    this.form.get(`var${index}`).setValue('Departamento');
  }

  selectVarProtocol(index: number) {
    this.form.get(`var${index}`).setValue('Nº do protocolo');
  }

  trackByFn(index: number, item: any) {
    return index;
  }

  cancelSelection() {
    this.selectedTemplate = null;
    this.previewText = '';
    this.form.reset();
  }

}
