import { Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractComponent, Attendance, AttendanceStatusEnum, Contact, ContactService, CustomField, CustomFieldContact, CustomFieldService } from 'lib-trend-core';
import { Observable } from 'rxjs';
import { getAttendance } from '../../state/actions';
import { AppState } from '../../state/app.state';
import { attendanceSelector } from '../../state/selectors';

@Component({
  selector: 'custom-field-attendance',
  templateUrl: './custom-field-attendance.component.html',
  styleUrls: ['./custom-field-attendance.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomFieldAttendanceComponent extends AbstractComponent implements OnInit {
  contact!: Contact;

  readonly attendance$: Observable<Attendance> = this.store.select(attendanceSelector);

  listCustomField: Array<CustomFieldContact> = new Array<CustomFieldContact>();
  listFieldsCustom: Array<CustomField> = new Array<CustomField>();

  attendance: Attendance;

  showSelectCustomField: boolean = true;

  constructor(
    injector: Injector,
    private store: Store<AppState>,
    public customFieldService: CustomFieldService,
    public contactService: ContactService,
  ) {
    super(injector);
    this.createForm();
  }

  ngOnInit() {
    this.attendance$.subscribe((attendance: Attendance) => {
      this.attendance = attendance;
      this.contact = attendance?.contact;

      this.formGroup = this.formBuilder.group({
        selectedFields: [[]]
      });

      if (this.contact?.customFields) {
        const selectedFieldIds = this.contact?.customFields.map(field => field._id) || [];
        this.formGroup.get('selectedFields')?.setValue(selectedFieldIds);

        this.contact?.customFields.forEach(field => {
          this.formGroup.addControl(field._id, this.formBuilder.control(field.value));
        });

        this.contact.customFields.forEach(field => {
          this.formGroup.addControl(field._id, this.formBuilder.control(field.value || ''));
        });
        this.listCustomField = this.contact.customFields;
      }
    });
    this.getListCustomField();
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      selectedFields: [[]]
    });
  }

  getListCustomField(): void {
    this.customFieldService.getList().subscribe((list: Array<CustomField>) => {
      this.listFieldsCustom = list;
    });
  }

  toogleSelectCustomField(): void {
    this.showSelectCustomField = false;
  }

  addCustomField(selectedIds: Array<string>): void {
    const updatedContact = {
      ...this.contact,
      customFields: [...(this.contact?.customFields || [])]
    };

    const currentIds = updatedContact.customFields.map(field => field._id);

    const addedFields = selectedIds.filter(id => !currentIds.includes(id));

    const removedFields = currentIds.filter(id => !selectedIds.includes(id));

    addedFields.forEach(idCustomField => {
      const selectedField = this.listFieldsCustom.find(field => field._id === idCustomField);
      if (selectedField) {
        updatedContact.customFields.push({
          _id: selectedField._id,
          name: selectedField.name,
          description: selectedField.description,
          type: selectedField.type,
          value: ''
        });
        this.formGroup.addControl(selectedField._id, this.formBuilder.control(null));
      }
    });

    removedFields.forEach(idCustomField => {
      updatedContact.customFields = updatedContact.customFields.filter(field => field._id !== idCustomField);
      if (this.formGroup.contains(idCustomField)) {
        this.formGroup.removeControl(idCustomField);
      }
    });

    this.contact = updatedContact;
    this.updateContact();
  }


  editCustomField(idCustomField: string): void {
    const newValue = this.formGroup.get(idCustomField)?.value;
    const updatedCustomFields = this.contact.customFields.map(field =>
      field._id === idCustomField ? { ...field, value: newValue } : field
    );

    this.contact = {
      ...this.contact,
      customFields: updatedCustomFields
    };

    this.updateContact();
  }

  removeCustomField(idCustomField: string): void {
    const updatedContact = {
      ...this.contact,
      customFields: this.contact?.customFields.filter(field => field._id !== idCustomField),
    };

    this.contact = updatedContact;
    if (this.formGroup.contains(idCustomField)) {
      this.formGroup.removeControl(idCustomField);
    }

    this.updateContact();
  }

  updateContact() {
    if (this.attendance.status === AttendanceStatusEnum.CLOSED) {
      this.alertService.info('Não é possível adicionar ou remover campos personalizados para atendimentos com status fechado.');
      return;
    }

    this.contactService.update(this.contact._id, this.contact).subscribe({
      next: (value) => {
        this.alertService.success('Contato atualizado com sucesso!');
        this.showSelectCustomField = true;
        this.store.dispatch(getAttendance({ idAttendance: this.attendance._id }));
      },
      error: (err) => { this.alertService.error('Ops! Não foi possível atualizar o contato.') },
      complete: () => { }
    });
  }

  getFieldType(fieldId: string): string {
    const field = this.listFieldsCustom.find(item => item._id === fieldId);
    return field ? field.type : '';
  }
}
