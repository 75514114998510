
import { Component, Injector, Input, OnInit } from '@angular/core';
import { AbstractComponent, Message } from 'lib-trend-core';

@Component({
  selector: 'chat-message-reactions-component',
  templateUrl: './chat-message-reactions.component.html',
  styleUrls: ['./chat-message-reactions.component.scss']
})
export class ChatMessageReactionsComponent extends AbstractComponent implements OnInit {


  @Input() message: Message;

  constructor(
    injector: Injector,
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<any> {
  }

}


