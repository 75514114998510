import { Component, ElementRef, Injector, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AbstractComponent, Attendance, AttendanceService, AttendanceStatusEnum, ContactEditComponent, LoadMessageService, Message, Pager, SocketService, TabService } from 'lib-trend-core';
import { Observable, Subject, debounceTime, delay, distinctUntilChanged, map, of, switchMap, take, takeUntil, tap } from 'rxjs';
import { ContactNewConversationComponent } from '../attendance-panel-components/new-conversation/new-conversation.component';
import { getAttendance, getAttendances, getCounts, openCloseSidebarRight, setAttendance, setMessages } from '../state/actions';
import { AppState, CountState, PagerParamsState } from '../state/app.state';
import { attendancesSelector, countsSelector } from '../state/selectors';

@Component({
  selector: 'attendance-panel-list-component',
  templateUrl: 'attendance-panel-list.component.html',
  styleUrls: ['./attendance-panel-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AttendancePanelList extends AbstractComponent implements OnInit, OnDestroy {

  @ViewChild('btnUpdateAttendancesFromSocket') btnUpdateAttendancesFromSocket: ElementRef;

  // Index tab
  selectedIndex: number = 0;

  pager: Pager<Attendance> = new Pager<Attendance>({ perPage: 50 });

  public pager$: Observable<Pager<Attendance>> = this.store.select(attendancesSelector);
  public counts$: Observable<Array<CountState>> = this.store.select(countsSelector);

  private notificationAudio = new Audio('../../../../../../../assets/audio/alert-message.mp3');

  listObservable: Observable<void>;
  private termoDaBusca: Subject<string> = new Subject<string>();

  overtimeCount: number = 0;
  pendingCount: number = 0;
  inProgressCount: number = 0;
  pausedCount: number = 0;
  closedCount: number = 0;
  attendance: Attendance;
  isAudioOn: boolean = true;

  pagerParamsState: PagerParamsState;

  idAttendanceParam!: string;

  loadingMore: boolean = false;

  constructor(
    injector: Injector,
    private store: Store<AppState>,
    private loadMessageService: LoadMessageService,
    private tabService: TabService,
    public dialog: MatDialog,
    public socketService: SocketService,
    public attendanceService: AttendanceService,
  ) {
    super(injector);
    this.createForm();
    this.getAttendanceByParam();
  }

  ngOnInit(): void {
    this.formGroup.controls['status'].valueChanges.subscribe(() => {
      this.getList();
    });
    this.getList();
    this.setupObservableSearch();

    this.tabService.indexTab$.subscribe((index) => {
      this.selectedIndex = index;
    });

    const savedAudioState = localStorage.getItem('audioState');
    if (savedAudioState !== null) {
      this.isAudioOn = JSON.parse(savedAudioState);
    }

    this.pager$.pipe(
      tap((pager: Pager<Attendance>) => {
        if (this.loadingMore) {
          this.pager.page = pager.page;
          this.pager.total = pager.total;
          this.pager.perPage = pager.perPage;
          this.pager.list.push(...pager.list)
          this.loadingMore = false;
        } else {
          this.pager = pager;
        }
      }),
      map(() => this.counts$.subscribe((counts: Array<CountState>) => {
        this.setValuesCountsAttendance(counts);
      }),
      )).subscribe(() => {
        this.loading = false;
      });

    this.loadMessageService.eventLoadMessage$.subscribe((result) => {
      this.loading = result;
    });
    this.configSocket();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getAttendanceByParam(): void {
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      const status = params['status'];

      this.idAttendanceParam = id;

      if (id) {
        this.store.dispatch(getAttendance({ idAttendance: id }));

        switch (!!status) {
          case status === AttendanceStatusEnum.PENDING: {
            this.setTabIndexAndStatus(1, status);
            break;
          }
          case status === AttendanceStatusEnum.IN_PROGRESS: {
            this.setTabIndexAndStatus(2, status);
            break;
          }
          case status === AttendanceStatusEnum.PAUSED: {
            this.setTabIndexAndStatus(3, status);
            break;
          }
          case status === AttendanceStatusEnum.CLOSED: {
            this.setTabIndexAndStatus(4, status);
            break;
          }
          default: {
            this.setTabIndexAndStatus(0, status);
            break;
          }
        }
      }
    });
  }

  private setTabIndexAndStatus(index: number, status: AttendanceStatusEnum): void {
    this.tabService.selectedTabIndex(index);
    this.formGroup.get('status').setValue(status, { emitEvent: false });
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      status: [AttendanceStatusEnum.OVERTIME],
    });
  }

  private setupObservableSearch() {
    this.listObservable = this.termoDaBusca
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.loading = true;
          this.pager.list = [];
        }),
        switchMap(term => {
          return of(this.store.dispatch(getAttendances({ page: this.pager.page, perPage: this.pager.perPage, search: term, params: this.searchParams })));
        }),
        delay(200)
      );
    this.listObservable.subscribe(() => {
      this.loading = false;
    });
  }

  search(termo: string) {
    this.termoDaBusca.next(termo);
  }

  private getList() {
    this.loading = true;
    this.searchParams = {
      company: this.getIDCurrentCompany(),
      status: this.formGroup.get('status').value
    };
    this.store.dispatch(getCounts());
    this.store.dispatch(getAttendances({
      page: this.pager.page,
      perPage: this.pager.perPage,
      search: this.searchString,
      params: this.searchParams
    }));
  }

  changeTab(event: any) {
    this.pager = new Pager<Attendance>({ perPage: 50 });
    let newStatus = AttendanceStatusEnum.OVERTIME;
    switch (event.index) {
      case 1:
        newStatus = AttendanceStatusEnum.PENDING;
        break;
      case 2:
        newStatus = AttendanceStatusEnum.IN_PROGRESS;
        break;
      case 3:
        newStatus = AttendanceStatusEnum.PAUSED;
        break;
      case 4:
        newStatus = AttendanceStatusEnum.CLOSED;
        break;
      default:
        newStatus = AttendanceStatusEnum.OVERTIME;
    }
    this.formGroup.get('status').setValue(newStatus, { emitEvent: false });
    this.getList();
  }

  addContact(): void {
    this.dialog.open(ContactEditComponent, {
      width: '600px'
    });
  }

  startNewChat(): void {
    this.dialog.open(ContactNewConversationComponent, {
      width: '60rem',
      minHeight: '25rem',
      maxHeight: '50rem',
    });
  }

  selectAttendance(attendance: Attendance): void {
    if (!!this.idAttendanceParam) {
      this.router.navigate([], {
        queryParams: { id: null, status: null },
        queryParamsHandling: 'merge',
        replaceUrl: true
      })
      this.idAttendanceParam = null;
    }

    if (this.attendance?._id !== attendance._id) {
      attendance = { ...attendance, countUnreadMessages: 0 };
      this.attendance = attendance;
      this.store.dispatch(setMessages({ messages: new Array<Message>() }));
      this.store.dispatch(setAttendance({ attendance: this.attendance }));
      this.store.dispatch(openCloseSidebarRight({ sidebarRight: false }));
      this.loadMessageService.setLoadingMessage(true);
    }
  }

  private setValuesCountsAttendance(counts: Array<{ count: number, status: AttendanceStatusEnum }>): void {
    this.overtimeCount = counts?.find((value) => value.status === AttendanceStatusEnum.OVERTIME)?.count || 0;
    this.pendingCount = counts?.find((value) => value.status === AttendanceStatusEnum.PENDING)?.count || 0;
    this.inProgressCount = counts?.find((value) => value.status === AttendanceStatusEnum.IN_PROGRESS)?.count || 0;
    this.pausedCount = counts?.find((value) => value.status === AttendanceStatusEnum.PAUSED)?.count || 0;
    this.closedCount = counts?.find((value) => value.status === AttendanceStatusEnum.CLOSED)?.count || 0;
  }

  showAddContact(): boolean {
    return this.getCurrentUserUser().addLeads;
  }

  toggleAudio() {
    this.isAudioOn = !this.isAudioOn;

    localStorage.setItem('audioState', JSON.stringify(this.isAudioOn));
  }

  tmpAttendanceFromSocket: Attendance;

  updateAttendancesFromSocket() {
    this.store.dispatch(getCounts());
    this.store.dispatch(getAttendances({
      page: this.pager.page,
      perPage: this.pager.perPage,
      search: this.searchString,
      params: this.searchParams
    }));

    const selectedStatus = this.formGroup.get('status').value;
    if (selectedStatus === this.tmpAttendanceFromSocket.status) {
      const audioState = localStorage.getItem('audioState');
      const isAudioOn = audioState !== null ? JSON.parse(audioState) : true;
      const currentUserId = this.getIDCurrentUser();
      const assignedUserId = this.tmpAttendanceFromSocket.user?._id;
      if (currentUserId === assignedUserId && isAudioOn) {
        if (!this.attendance || this.attendance._id !== this.tmpAttendanceFromSocket._id) {
          this.notificationAudio.play();
        }
      }
    }
  }

  configSocket() {
    const code = super.getCodeCompanyCurrentUser();
    const sname = `event_attendances_${code}`;
    this.socketService.listen(sname)
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(
        (attendance: Attendance) => {
          this.tmpAttendanceFromSocket = attendance;
          this.btnUpdateAttendancesFromSocket.nativeElement.click();
        }
      );
  }

  onScroll(event: Event): void {
    const target = event.target as HTMLElement;

    const buffer = 400;

    if (target.scrollHeight - target.scrollTop <= target.clientHeight + buffer) {
      this.loadMoreContacts();
    }
  }

  loadMoreContacts(): void {
    if (this.pager.list.length < this.pager.total) {
      this.loading = true;
      this.loadingMore = true;
      this.store.dispatch(getAttendances({
        page: this.pager.page + 1,
        perPage: this.pager.perPage,
        search: this.searchString,
        params: this.searchParams
      }));
    }
  }
}
