import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, Injector, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TemplateMessageObject } from '@trendbuild/trend-cloud-api';
import { AbstractComponent, AlertModalComponent, Attendance, AttendanceService, AttendanceStatusEnum, AudioService, ChannelService, ChannelTypeEnum, ConfirmationComponent, ContactService, FirebaseService, LoadMessageService, Message, MessageHelper, MessageService, MessageTypeEnum, ModalResponseFastComponent, PreviewMediaComponent, ResponseService, SocketService, TabService, Traduct, UploadTypeEnum, UserService, UtilHelper } from 'lib-trend-core';
import { forkJoin, Observable, of, takeUntil } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AssignedUserComponent } from '../attendance-panel-components/assigned-user/assigned-user.component';
import { ChatSelectTemplateComponent } from '../attendance-panel-components/chat/chat-select-template/chat-select-template.component';
import { AttendancePanelInfoComponent } from '../attendance-panel-info/attendance-panel-info.component';
import { getAttendance, getAttendances, getMessages, getNotes, getTags, openCloseSidebarRight, setAttendance } from '../state/actions';
import { AppState, PagerParamsState } from '../state/app.state';
import { attendanceSelector, messagesSelector, openCloseSidebarRightSelector, pagerParamsSelector } from '../state/selectors';

@Component({
  selector: 'attendance-panel-chat-component',
  templateUrl: './attendance-panel-chat.component.html',
  styleUrls: ['./attendance-panel-chat.component.scss']
})
export class AttendancePanelChatComponent extends AbstractComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('btnUpdateChatFromSocket') btnUpdateChatFromSocket: ElementRef;
  @ViewChild('btnUpdateMessagesFromSocket') btnUpdateMessagesFromSocket: ElementRef;

  @ViewChild('transferModal') transferModal: TemplateRef<any>;
  @ViewChild('inputImagefile', { static: false }) inputImagefile: ElementRef<HTMLInputElement>;
  @ViewChild('inputVideofile', { static: false }) inputVideofile: ElementRef<HTMLInputElement>;
  @ViewChild('inputDocfile', { static: false }) inputDocfile: ElementRef<HTMLInputElement>;
  @ViewChild('inputAudiofile', { static: false }) inputAudiofile: ElementRef<HTMLInputElement>;
  @ViewChild(AttendancePanelInfoComponent) panelInfo: AttendancePanelInfoComponent;
  @ViewChild('messageContainer') messageContainer: ElementRef<HTMLDivElement>;
  @ViewChild('audioContainer', { static: false }) audioContainer!: ElementRef;

  readonly attendance$: Observable<Attendance> = this.store.select(attendanceSelector);
  readonly messages$: Observable<Array<Message>> = this.store.select(messagesSelector);
  readonly pagerParams$: Observable<PagerParamsState> = this.store.select(pagerParamsSelector);
  readonly sidebarRight$: Observable<boolean> = this.store.select(openCloseSidebarRightSelector);

  attendance: Attendance;
  messages: Array<Message> = [];

  sidebarRight: boolean = false;
  isInputFocused: boolean = false;
  showContainerEmoji: boolean = false;

  textEmoji!: any;
  translator!: Traduct;
  dialogRef: MatDialogRef<any>;
  pagerParamsState: PagerParamsState;

  isRecording!: boolean;
  stream!: MediaStream;
  recorder!: MediaRecorder;
  audioChunks: Blob[] = [];
  isCancel!: boolean;
  audioUrl!: string | null;
  isPreview: boolean = false;
  audioFile!: File | undefined;
  recordingTime: number = 0;
  intervalId!: any;
  isLoading: boolean = false;

  lastMessageDate: Date;
  dateFormatted!: string;

  selectedIndexTab: number = 0;
  showEmpty: boolean = false;

  statusPaused: AttendanceStatusEnum = AttendanceStatusEnum.PAUSED;
  statusInProgress: AttendanceStatusEnum = AttendanceStatusEnum.IN_PROGRESS;

  attendanceSelected!: Attendance;

  constructor(
    injector: Injector,
    private loadMessage: LoadMessageService,
    private modalResponse: MatDialog,
    private store: Store<AppState>,
    private tabService: TabService,
    private audioService: AudioService,
    private modalUploadPreview: MatDialog,
    private modalAlert: MatDialog,
    public dialog: MatDialog,
    public messageService: MessageService,
    public userService: UserService,
    public contactService: ContactService,
    public channelService: ChannelService,
    public attendanceService: AttendanceService,
    public socketService: SocketService,
    public firebaseService: FirebaseService,
    public responseService: ResponseService,
  ) {
    super(injector);
    this.translator = UtilHelper.getEmojiTranslator();
  }

  @HostListener('document:paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    const items = event.clipboardData?.items;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.type.indexOf('image') !== -1) {
        const file = item.getAsFile();
        if (file) {
          this.onSelectMedia('image', file);
          event.preventDefault();
          event.stopPropagation();
        }
      }
    }
  }

  async ngOnInit(): Promise<any> {
    this.setupForm();
    this.initComponent();
  }

  ngAfterViewInit(): void {
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initComponent() {
    this.attendance$.subscribe(attendance => {
      if (!attendance) return;

      const idCompany: unknown = attendance.company._id ? attendance.company._id : attendance.company;
      if (idCompany !== super.getIDCurrentCompany()) {
        this.alertService.info(`Não foi possível localizar esse atendimento na empresa: ${super.getNameCompanyCurrentUser()}`);
        return;
      }

      sessionStorage.removeItem('preview_audio');
      this.audioUrl = null;
      this.isLoading = false;
      if (this.attendance?._id !== attendance._id) {
        this.formGroup.reset('');
      }
      this.attendance = attendance;
      this.messages = [];
      this.destroy$.next(); // clear all subscriptions of last attendances messages
      this.store.dispatch(getMessages({ idAttendance: this.attendance._id }));
      this.configSocket();
    });

    this.messages$.subscribe(messages => {
      this.messages = messages;
      this.loadMessage.setLoadingMessage(false);
    });

    this.sidebarRight$.subscribe(sidebarRight => {
      this.sidebarRight = sidebarRight;
    });
    this.pagerParams$.subscribe(pager => {
      this.pagerParamsState = pager;
    });
  }

  private startRecording(idAttendance: string): void {
    if (this.isRecording) return;

    this.recordingTime = 0;
    this.intervalId = setInterval(() => {
      this.recordingTime++;
    }, 1000);

    sessionStorage.removeItem('preview_audio');

    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        this.isRecording = true;
        this.isCancel = false;
        this.stream = stream;
        this.recorder = new MediaRecorder(this.stream);
        this.audioChunks = [];

        this.recorder.ondataavailable = (event) => {
          if (!this.isCancel) {
            this.audioChunks.push(event.data);
          }
        };

        this.recorder.onstop = async () => {
          if (!this.isCancel) {
            const audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' });
            const filename = `audio.mp3`;
            const newBlob = await this.audioService.convertWebMToMp3(audioBlob);
            this.audioFile = new File([newBlob], filename, { type: 'audio/mpeg' });

            if (this.isPreview) {
              const reader = new FileReader();
              reader.onload = () => {
                sessionStorage.setItem(`preview_audio_${idAttendance}`, reader.result as string);
              };
              reader.onerror = (error) => {
                this.alertService.error('Ops! Ocorreu um erro ao tentar ler o seu áudio. Tente novamente mais tarde.');
              };
              reader.readAsDataURL(this.audioFile);

              this.audioUrl = URL.createObjectURL(audioBlob);
              this.isPreview = false;
            }
          } else {
            this.audioChunks = [];
          }
        };

        this.recorder.start();
      }).catch((err) => this.alertService.error(err));
  }

  stopRecording(): void {
    if (this.isRecording) {
      clearInterval(this.intervalId);
      this.recorder.stop();
      this.isRecording = false;
      this.stream?.getTracks().forEach(track => track.stop());
    }
  }

  cancelRecording(): void {
    if (this.recorder && this.isRecording) {
      this.isCancel = true;
      this.recorder.stop();
      this.isRecording = false;
      this.stream?.getTracks().forEach(track => track.stop());
    }
  }

  toggleRecording(idAttendance: string): void {
    if (this.isRecording) {
      this.isLoading = true;
      this.stopRecording();
      this.audioUrl = sessionStorage.getItem(`preview_audio_${idAttendance}`);
    } else {
      this.isLoading = false;
      this.isPreview = true;
      this.startRecording(idAttendance);
    }
  }

  cancelPreview(idAttendance: string): void {
    this.isLoading = false;
    sessionStorage.removeItem(`preview_audio_${idAttendance}`);
    this.audioUrl = null;
    this.audioFile = undefined;

    if (this.audioContainer) this.audioContainer.nativeElement.innerHTML = '';
  }

  sendAudio(attendance: Attendance): void {
    const maxSize = 5 * 1024 * 1024;

    if (this.audioFile && this.audioFile.size > maxSize) {
      this.alertService.error('Ops! Você gravou um áudio muito grande. Só é permitido 5MB.');
      return;
    }

    this.isLoading = false;
    sessionStorage.removeItem(`preview_audio_${attendance._id}`);
    this.onSendAudio(attendance, MessageTypeEnum.audio, this.audioFile);
    this.audioUrl = null;

    if (this.audioContainer) this.audioContainer.nativeElement.innerHTML = '';
  }

  getFormattedTime(): string {
    const minutes: string = Math.floor(this.recordingTime / 60).toString().padStart(2, '0');
    const seconds: string = (this.recordingTime % 60).toString().padStart(2, '0');

    return `${minutes}:${seconds}`;
  }

  private refreshStoreAttendancePage() {
    if (!environment.socketActived) {
      this.store.dispatch(getMessages({ idAttendance: this.attendance._id }));
    }
  }

  private setupForm() {
    this.formGroup = this.formBuilder.group({
      message: [null, Validators.required],
    });
  }

  sendText() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    const text = this.formGroup.get('message').value;
    this.replyText(this.attendance, text);

    this.formGroup.reset('');
  }

  sendMedia(mediaType: 'image' | 'file' | 'location' | 'audio' | 'video' | 'document') {
    switch (mediaType) {
      case 'image': this.inputImagefile.nativeElement.click(); break;
      case 'video': this.inputVideofile.nativeElement.click(); break;
      case 'document': this.inputDocfile.nativeElement.click(); break;
      case 'audio': this.inputAudiofile.nativeElement.click(); break;
    }
  }

  selectTemplate() {
    const dialogRef = this.dialog.open(ChatSelectTemplateComponent, {
      width: '1000px',
      data: this.attendance,
      height: '587px'
    });

    dialogRef.afterClosed().subscribe(metadata => {
      if (metadata) {
        const template = <TemplateMessageObject>metadata.template;
        const params = metadata.params;
        const link = metadata.link;
        const previewText = metadata.previewText;
        this.replyTextTemplate(this.attendance, template, link ?? previewText, params);
      }
    });
  }

  onSelectMedia(type: string, fileOrEvent: File | any): void {
    if (!this.attendance?.user) {
      this.alertService.success("Nenhum atendente foi definido para o atendimento.");
      return;
    }

    const file: File = fileOrEvent instanceof File ? fileOrEvent : (fileOrEvent.target as HTMLInputElement)?.files?.[0];
    const fileTypeLimit = UtilHelper.getFileTypeLimit(file.type);

    if (fileTypeLimit && file.size > fileTypeLimit.maxSize) { // Check type and max size of file
      this.openModalAlert(fileTypeLimit.alertTitle, fileTypeLimit.alertMessage, this.getInputElement(file.type));
      return;
    }

    if (file) {
      const dialogRefUpload = this.modalUploadPreview.open(PreviewMediaComponent, {
        width: '600px',
        data: { type: type, url: '', filename: file.name },
      });

      const reader = new FileReader();
      reader.onload = async (fileEvent) => {
        try {
          this.loading = true;

          const dateMilisecond = new Date().getTime();
          const filename: string = file.name + '_' + dateMilisecond.toString();

          this.firebaseService.uploadFile(file, filename, UploadTypeEnum.MESSAGE).then((snapshot: { url: string }) => {
            dialogRefUpload.componentInstance.showPreview(snapshot.url);
            this.loading = false;

            dialogRefUpload.afterClosed().subscribe((result) => {
              if (result) {
                this.replyMedia(this.attendance, <MessageTypeEnum>type, result.midia, file.type);
              }
              this.resetInputFiles();
            });
          });
        } catch (e) {
          console.error(e);
        }
      };
      reader.readAsArrayBuffer(file);
    } else {
      this.alertService.error('Ops! Não foi possível realizar o upload do arquivo.');
      return;
    }
  }

  replyTextTemplate(attendance: Attendance, template: TemplateMessageObject, content?: string, params?: string[]): void {
    const message = MessageHelper.createMessageFromAttendance(attendance, MessageTypeEnum.template, content, null, super.getIDCurrentUser());
    message.metadata = {
      template,
      params: params ?? [],
    };
    this.createPostMessage(message);
  }

  replyText(attendance: Attendance, text: string): void {
    this.createPostMessage(MessageHelper.createMessageFromAttendance(attendance, MessageTypeEnum.text, text, null, super.getIDCurrentUser()));
  }

  replyMedia(attendance: Attendance, type: MessageTypeEnum, content: string, contentType: string, caption?: string): void {
    if (this.attendance._id !== attendance._id) {
      this.alertService.warning('O áudio foi cancelado devido a troca de atendimentos.');
      sessionStorage.removeItem(`preview_audio_${attendance._id}`);
      if (this.audioContainer) this.audioContainer.nativeElement.innerHTML = '';
      return;
    }

    const message = caption && (type === 'image' || type === 'document' || type === 'video')
      ? MessageHelper.createMessageFromAttendance(attendance, type, content, contentType, super.getIDCurrentUser(), caption)
      : MessageHelper.createMessageFromAttendance(attendance, type, content, contentType, super.getIDCurrentUser());

    this.createPostMessage(message);
  }

  private createPostMessage(message: Message) {
    if (message.type === MessageTypeEnum.text && super.getSignatureConversation()) {
      message.content = `> _*${this.getNameCurrentUser()}*_ \n\n ${message.content}`;
    }

    let assignUserSubscription = of({});
    const shouldAssignUser = !this.attendance.user || this.attendance.status !== AttendanceStatusEnum.IN_PROGRESS;
    if (shouldAssignUser) {
      assignUserSubscription = this.attendanceService.assignUser(this.attendance._id, super.getIDCurrentUser());
    }
    forkJoin({ assignUserSubscription, messageServiceCreateSubscription: this.messageService.create(message) }).subscribe(
      ({ assignUserSubscription }) => {
        if (!!assignUserSubscription && shouldAssignUser) {
          this.attendance = <Attendance>assignUserSubscription;
        }
        if (super.getIDCurrentUser() !== this.attendance.user?._id) {
          this.alertService.success(`${super.getNameCurrentUser()} assumiu o atendimento.`);
        }
        this.tabService.selectedTabIndex(2);
      });
  }

  toggleSidebar() {
    this.sidebarRight = !this.sidebarRight;
    this.store.dispatch(getAttendance({ idAttendance: this.attendance._id }))
    this.store.dispatch(openCloseSidebarRight({ sidebarRight: this.sidebarRight }));
    this.store.dispatch(getNotes({ idAttendance: this.attendance._id }));
    this.store.dispatch(getTags());
  }

  focusInput(): void {
    this.isInputFocused = true;
  }

  blurInput(): void {
    this.isInputFocused = false;
  }

  enterEventPress(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.sendText();
  }

  toogleContainerEmoji(): void {
    this.showContainerEmoji = !this.showContainerEmoji;
  }

  addEmoji(event: any): void {
    const messageFg = this.formGroup.get('message');
    const currentText = messageFg.value || '';

    messageFg.setValue(currentText + event.emoji.native);
  }

  closedContainerEmoji(): void {
    if (this.showContainerEmoji) {
      this.showContainerEmoji = false;
    }
  }

  openAssignedUserTransferModal() {
    const dialogRef = this.dialog.open(AssignedUserComponent, {
      width: '600px',
      data: this.attendance
    });
    dialogRef.afterClosed().subscribe((attendance: Attendance) => {
      if (!!attendance) {
        this.store.dispatch(setAttendance({ attendance }));
        this.tabService.selectedTabIndex(1);
      }
    });
  }

  closeAttendance() {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: { question: 'Tem certeza que deseja encerrar o atendimento?' },
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (Boolean(result) === true) {
        this.attendanceService.closeAttendance(this.attendance._id).subscribe({
          next: (attendance: Attendance) => {
            this.store.dispatch(setAttendance({ attendance: undefined }));
            this.alertService.success('Atendimento encerrado com sucesso!');
          },
          error: (err) => this.alertService.error(err.error.message)
        });
      };
    });
  }

  openModalResponse(): void {
    const refBottom = this.modalResponse.open(ModalResponseFastComponent, { width: '70rem' });

    refBottom.afterClosed().subscribe(async (result) => {
      if (result !== null && result !== undefined) {
        let messageFg = this.formGroup.get('message');

        if (result.midia || result.midia === 'N/A') {
          const mediaType = this.helperExtension(result.midia);
          const isCaptionSend = mediaType === 'image' || mediaType === 'video' || mediaType === 'document';

          if (isCaptionSend) {
            this.replyMedia(this.attendance, mediaType, result.midia, result.response.contentType, result.message);
          } else {
            this.replyMedia(this.attendance, mediaType, result.midia, result.response.contentType);
            setTimeout(() => {
              this.replyText(this.attendance, result.message);
            }, 700);
          }
        } else {
          messageFg.setValue(result.message);
        }
      };
    });
  };

  formatExpireIn(date: Date): { text: string, isExpired: boolean } {
    return UtilHelper.formatExpireIn(date);
  }

  isExpiredisConversation(): boolean {
    if (this.attendance?.channel?.type === ChannelTypeEnum.CLOUD_API) {
      const isConversation = this.attendance?.inConversation;
      if (isConversation) {
        const expiredAtDate = new Date(this.attendance.expiredAt);
        const currentDate = new Date();
        const isExpired = expiredAtDate < currentDate;
        return isExpired ? !isConversation : isConversation;
      }
      return false;
    } else {
      return true;
    }
  }

  private helperExtension(midia: string): MessageTypeEnum {
    const decodedUrl = decodeURIComponent(midia);

    const fileNameWithQuery = decodedUrl.split('/').pop() || '';
    const fileName = fileNameWithQuery.split('?')[0];

    const extension = fileName.split('.').pop()?.toLowerCase();

    const regex = /_(\d+)/;
    const nameWithoutPart = extension.replace(regex, '');

    switch (nameWithoutPart) {
      case 'mp4':
        return MessageTypeEnum.video;
      case 'pdf':
        return MessageTypeEnum.document;
      case 'mp3':
        return MessageTypeEnum.audio;
      default:
        return MessageTypeEnum.image;
    }
  }

  formatDuration(duration: number): string {
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  updateStatusAttendance(status: AttendanceStatusEnum): void {
    this.attendanceService.updateStatus(this.attendance._id, status).subscribe({
      next: (attendance: Attendance) => {
        this.attendance = attendance;
        if (attendance.status === this.statusPaused) {
          this.alertService.success('Atendimento pausado com sucesso!');
          this.tabService.selectedTabIndex(3);
        } else {
          this.alertService.success('Atendimento em continuidade!');
          this.tabService.selectedTabIndex(2);
        }
      },
      complete: () => {
        this.refreshStoreAttendancePage();
      },
      error: () => {
        this.alertService.error('Ops! Ocorreu um erro ao tentar pausar o atendimento. Tente novamente mais tarde.');
      },
    });
  }

  private onSendAudio(attendance: Attendance, type: MessageTypeEnum, fileOrEvent: File | any): void {
    if (!this.attendance?.user) {
      this.alertService.success("Nenhum atendente foi definido para o atendimento.");
      return;
    }

    const file: File = fileOrEvent instanceof File ? fileOrEvent : (fileOrEvent.target as HTMLInputElement)?.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (fileEvent) => {
        try {
          this.loading = true;

          const dateMilisecond = new Date().getTime();
          const filename: string = file.name + '_' + dateMilisecond.toString();
          const contentType: string = file.type;

          this.firebaseService.uploadFile(file, filename, UploadTypeEnum.MESSAGE).then((snapshot: { url: string }) => {
            this.replyMedia(attendance, type, snapshot.url, contentType, filename);
          });
        } catch (e) {
          this.alertService.error('Ops! Não foi possível realizar o upload do arquivo.')
        }
      };
      reader.readAsArrayBuffer(file);
    } else {
      this.alertService.error('Ops! Ocorreu um erro inesperado ao enviar o audio. Tente novamente mais tarde.');
      return;
    }
  }

  private resetInputFiles(): void {
    this.inputImagefile.nativeElement.value = '';
    this.inputVideofile.nativeElement.value = '';
    this.inputAudiofile.nativeElement.value = '';
    this.inputDocfile.nativeElement.value = '';
  }

  private openModalAlert(title: string, message: string, inputElementFile: ElementRef<HTMLInputElement>): void {
    const dialogRefAlert = this.modalAlert.open(AlertModalComponent, {
      width: '600px',
      maxHeight: '300px',
      data: { title: title, message: message },
    });
    dialogRefAlert.afterClosed().subscribe(() => inputElementFile.nativeElement.value = '');
  }

  private getInputElement(fileType: string): ElementRef {
    if (fileType.startsWith('image/')) {
      return this.inputImagefile;
    } else if (fileType.startsWith('video/')) {
      return this.inputVideofile;
    } else if (fileType.startsWith('audio/')) {
      return this.inputAudiofile;
    } else {
      return this.inputDocfile;
    }
  }

  tmpAttendanceFromSocket: Attendance;
  tmpMessageFromSocket: Message;

  updateChatFromSocket() {
    if (this.attendance?._id === this.tmpAttendanceFromSocket._id) {
      const isAttendance = super.isAttendant();
      const isUserLogged = super.getIDCurrentUser();
      if (isAttendance && this.attendance.user?._id !== isUserLogged) {
        this.attendance = null;
      } else {
        this.attendance = this.tmpAttendanceFromSocket;
      }
    }
  }

  updateMessagesFromSocket() {
    if (this.tmpMessageFromSocket.attendance._id === this.attendance._id) {
      this.messages = [...this.messages, this.tmpMessageFromSocket];
    }
  }

  private configSocket(): void {
    if (!!this.attendance) {
      const code = super.getCodeCompanyCurrentUser();
      const phone = this.attendance.contact.phone;
      const idAttendance = this.attendance._id;

      const snameAttendance = `event_attendance_${code}_${this.attendance._id}`;
      const snameMessage = `event_message_${code}_${phone}_${idAttendance}`;

      this.socketService.listen(snameAttendance)
        .pipe(
          takeUntil(this.destroy$)
        ).subscribe(
          (attendance: Attendance) => {
            this.tmpAttendanceFromSocket = attendance;
            this.btnUpdateChatFromSocket.nativeElement.click();
          }
        );

      this.socketService.listen(snameMessage)
        .pipe(
          takeUntil(this.destroy$)
        ).subscribe(
          (message: Message) => {
            this.tmpMessageFromSocket = message;
            this.btnUpdateMessagesFromSocket.nativeElement.click();
          }
        );
    }
  }

}
