<loading-component class="loading" *ngIf="loadingSpinner$ | async"></loading-component>
<div class="container-full flex flex-col justify-content-center">
  <svg class="mb-3" width="52" height="52" id="Camada_2" data-name="Camada 2" xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 976.36 976.36">
    <defs>
      <style>
        .cls-1 {
          fill: #d1fadf;
        }

        .cls-1,
        .cls-2,
        .cls-3 {
          stroke-width: 0px;
        }

        .cls-2 {
          fill: #ecfdf3;
        }

        .cls-3 {
          fill: #039855;
        }
      </style>
    </defs>
    <g id="Camada_1-2" data-name="Camada 1">
      <g>
        <g>
          <circle class="cls-2" cx="488.18" cy="488.18" r="488.18" />
          <circle class="cls-1" cx="488.18" cy="488.18" r="348.88" />
        </g>
        <g>
          <path class="cls-3"
            d="m375.08,327.94h162c8.63,1.34,15.14,6.24,21.2,12.18,9.91,9.7,20,19.23,30.16,28.67,7.41,6.89,10.84,15.21,10.79,25.31-.13,29.02-.05,58.05-.05,87.08v3.95c1.28-1.47,1.91-2.19,2.53-2.92,9.11-10.6,17.72-21.67,27.46-31.65,8.52-8.73,19.41-10.05,30.75-5.41,10.14,4.15,17.17,14.75,17.3,25.55.1,7.85-2.72,14.54-7.71,20.45-22.85,27.06-45.72,54.12-68.49,81.25-1.04,1.24-1.76,3.18-1.78,4.8-.13,16.01.15,32.02-.22,48.02-.11,4.77-1.03,9.89-3.03,14.18-5.31,11.4-15.02,16.37-27.39,16.38-39.16.02-78.33,0-117.49,0-23.8,0-47.59,0-71.39,0-10.11,0-18.8-3.44-24.51-11.96-2.7-4.03-3.9-9.07-5.79-13.65,0-92.21,0-184.41,0-276.62.17-.5.42-.98.5-1.5,1.2-7.11,4.65-12.88,10.15-17.53,4.36-3.69,9.57-5.32,14.96-6.59Zm147.27,19.21c-1.6,0-2.76,0-3.92,0-45.88,0-91.76,0-137.65,0-8.77,0-12.11,3.37-12.11,12.22,0,88.35,0,176.7,0,265.05,0,8.79,3.37,12.15,12.19,12.15,61.46,0,122.92-.13,184.38.12,11.36.04,15.36-3.52,14.81-14.85-.39-8.15-.08-16.34-.08-24.72-3.01,1.65-5.59,3.31-8.37,4.54-10.91,4.83-21.89,9.49-32.82,14.28-4.03,1.77-7.89,1.96-11.38-1.01-3.51-2.99-3.77-6.88-2.75-11.12,2.73-11.29,5.4-22.59,8.05-33.89,1.28-5.5,3.64-10.41,7.31-14.73,12.71-14.96,25.39-29.94,37.95-45.03,1.15-1.39,1.91-3.56,1.92-5.37.11-32.12.08-64.23.08-96.35v-3.66c-1.51,0-2.66,0-3.82,0-14.51,0-29.02.04-43.53-.02-6.28-.02-10.21-3.95-10.26-10.19-.07-8.22-.02-16.43-.02-24.65,0-7.45,0-14.91,0-22.77Zm47.19,202.92c5.03,4.21,9.8,8.22,14.71,12.34,17.78-21.07,35.35-41.9,53.03-62.84-5.06-4.24-9.79-8.21-14.66-12.29-17.69,20.93-35.24,41.7-53.08,62.8Zm8.29-164.64c-12.29-11.73-24.02-22.93-36.05-34.41v34.41h36.05Zm71.88,99.48c2.35-2.87,4.74-5.44,6.72-8.3,2.88-4.16,1.94-9.43-1.91-12.59-3.83-3.14-9.15-2.94-12.71.71-2.35,2.41-4.44,5.08-6.81,7.82,4.95,4.16,9.72,8.16,14.71,12.36Zm-92.5,79.9c-7.77,7.2-6.61,17.24-9.36,26.03,8.05-4.54,18.28-4.93,23.85-13.84-4.82-4.05-9.53-8.02-14.49-12.19Z" />
          <path class="cls-3"
            d="m375.08,327.94c-5.39,1.27-10.6,2.9-14.96,6.59-5.5,4.65-8.94,10.42-10.15,17.53-.09.51-.33,1-.5,1.5,0-8.54,0-17.08,0-25.61h25.61Z" />
          <path class="cls-3"
            d="m474.65,443.83c25.18,0,50.37-.02,75.55.01,7.52.01,12.31,6.17,9.97,12.71-1.4,3.9-4.3,5.98-8.37,6.43-1.06.12-2.13.05-3.2.05-49.51,0-99.03,0-148.54,0-7.68,0-12.15-3.54-12.15-9.6,0-6.07,4.47-9.6,12.15-9.6,24.86,0,49.73,0,74.59,0Z" />
          <path class="cls-3"
            d="m474.65,501.46c25.18,0,50.37-.02,75.55.01,7.51.01,12.3,6.18,9.96,12.72-1.4,3.9-4.3,5.97-8.37,6.42-1.06.12-2.13.05-3.2.05-49.51,0-99.03,0-148.54,0-7.67,0-12.15-3.55-12.14-9.61,0-6.07,4.46-9.6,12.15-9.6,24.86,0,49.73,0,74.59,0Z" />
          <path class="cls-3"
            d="m445.83,385.57c15.69,0,31.37-.05,47.06.02,7.38.03,12.06,6.52,9.57,13.02-1.45,3.77-4.83,6.1-9.25,6.14-7.26.07-14.51.02-21.77.02-24.33,0-48.66.02-72.99-.01-7.63-.01-12.43-6.41-9.89-13.02,1.51-3.93,5-6.15,9.89-6.16,15.79-.02,31.59,0,47.38,0Z" />
          <path class="cls-3"
            d="m445.84,559.09c15.69,0,31.37-.05,47.06.02,7.38.03,12.06,6.53,9.56,13.02-1.44,3.75-4.85,6.1-9.25,6.14-7.47.07-14.94.02-22.41.02-24.12,0-48.23.02-72.35-.01-7.63-.01-12.43-6.42-9.88-13.03,1.51-3.93,5-6.15,9.89-6.16,15.79-.02,31.59,0,47.38,0Z" />
        </g>
      </g>
    </g>
  </svg>
  <h1 class="title-modal">Editar Nota</h1>
  <h6 class="text-zinc-400 font-light text-sm my-2">Editar nota de atendimento</h6>
</div>

<div style="max-height: 400px; overflow-y: auto;">
  <ng-container *ngIf="notes$ | async as notes">
    <div *ngIf="notes.length > 0; else noNotes">
      <div *ngFor="let note of notes" (click)="selectNoteForEdit(note)"
        [class.bg-gray-200]="selectedNote && selectedNote._id === note._id"
        class="p-4 border rounded-lg my-2 cursor-pointer">
        <div class="flex justify-between items-center">
          <ng-container *ngIf="selectedNote && selectedNote._id === note._id; else viewMode">
            <form [formGroup]="formGroup" (ngSubmit)="save()" class="w-[300px]">
              <input class="input-field mr-4  " matInput formControlName="description" placeholder="Descrição da Nota">
            </form>
          </ng-container>
          <ng-template #viewMode>
            <p class="flex-1">{{ note.description }}</p>
          </ng-template>
          <div class="flex items-center">
            <span class="data-time-note">{{ note.updatedAt | date:'dd/MM/yyyy HH:mm' }}</span>
            <mat-icon *ngIf="!(selectedNote && selectedNote._id === note._id)" style="color: #4213F6;" class="ml-2 cursor-pointer" (click)="selectNoteForEdit(note)">edit</mat-icon>
            <mat-icon color="warn" *ngIf="selectedNote && selectedNote._id === note._id" class="ml-2 cursor-pointer" (click)="save()">check</mat-icon>
            <mat-icon class="ml-2 cursor-pointer" (click)="delete(note)" style="color: #4213F6;">delete</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #noNotes>
    <p class="text-gray-500 text-center m-4">Não há notas adicionadas</p>
  </ng-template>
</div>
<p class="mt-2 flex justify-end mr-2">Total de notas: <span class="text-blue-700"> {{ (notes$ | async)?.length }}</span></p>

<form [formGroup]="formGroup" (ngSubmit)="save()" class="mt-4">
  <div class="flex items-center mb-4">
    <input class="input-field mr-4"
           matInput
           formControlName="description"
           placeholder="Descrição da Nota"
           [attr.disabled]="selectedNote ? true : null">
    <button class="text-white bg-[#4213F6] rounded-lg p-2 mr-1 h-[46px]"
            type="submit"
            [attr.disabled]="selectedNote ? true : null">
      Adicionar
    </button>
  </div>
</form>
<div class="flex justify-between items-center w-full gap-2">
  <button [matDialogClose]="false"
          class="h-[46px] px-14 flex justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100 w-[47%]">Cancelar</button>
  <button class="h-[46px] px-14 flex justify-center items-center rounded-lg text-white font-normal bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none w-[47%]"
          [matDialogClose]="false">Confirmar</button>
</div>

