import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-trend-core';
import { ContacttModule } from '../contact/contact.module';
import { WaTemplateModule } from '../wa-template/wa-template.module';
import { ContactNewConversationComponent } from './new-conversation.component';

@NgModule({
  declarations: [
    ContactNewConversationComponent,
  ],
  exports: [
    ContactNewConversationComponent,
  ],
  imports: [
    SharedModule,
    WaTemplateModule,
    ContacttModule,
  ],

  providers: [],
})
export class ContactNewConversationModule { }
