import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-trend-core';
import { NoteAttendanceComponent } from './note-attendance.component';
import { EditNoteAttendanceComponent } from './edit-note-attendance/edit-note-attendance.component';
import { ListNoteAttendanceComponent } from './list-note-attendance/list-note-attendance.componente';


@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    NoteAttendanceComponent,
    EditNoteAttendanceComponent,
    ListNoteAttendanceComponent,  
  ],
  exports: [
    NoteAttendanceComponent,
    
  ],
  providers: [

  ]
})

export class NoteAttendanceModule { }
