import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Attendance, ConfirmationComponent, Note, NoteService } from 'lib-trend-core';
import { Store } from '@ngrx/store';
import { AppState } from '../../state/app.state';
import { Observable } from 'rxjs';
import { attendanceSelector, notesSelector } from '../../state/selectors';
import { setNotes } from '../../state/actions';
import { AlertService } from 'lib-trend-core';
import { EditNoteAttendanceComponent } from './edit-note-attendance/edit-note-attendance.component';
import { ListNoteAttendanceComponent } from './list-note-attendance/list-note-attendance.componente';


@Component({
  selector: 'service-note-attendance',
  templateUrl: './note-attendance.component.html',
  styleUrls: ['./note-attendance.component.scss']
})
export class NoteAttendanceComponent implements OnInit {

  readonly attendance$: Observable<Attendance> = this.store.select(attendanceSelector);
  readonly notes$: Observable<Array<Note>> = this.store.select(notesSelector);

  attendance: Attendance;
  formGroup: FormGroup;

  constructor(
    private store: Store<AppState>,
    public dialog: MatDialog,
    public noteService: NoteService,
    private formBuilder: FormBuilder,
    private alertService: AlertService
  ) {
    this.attendance$.subscribe(attendance => this.attendance = attendance);
    this.createForm();
  }

  ngOnInit(): void {}

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      description: ['', Validators.required],
    });
  }

  delete(note: Note): void {
    const dialogRef = this.dialog.open(ConfirmationComponent,{
    width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (Boolean(result) === true){
        this.noteService.delete(note._id).subscribe({
          next: (value) => {
            this.getList();
            this.alertService.success('Nota excluída com sucesso.');
          },
          error: (err) => this.alertService.error(err.error.message)
        });
      }
    })
  }

  edit(note: Note): void {
    const dialogRef = this.dialog.open(EditNoteAttendanceComponent, {
      width: '600px',
      data: { note: note }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.noteService.update(result._id, result).subscribe({
          next: () => {
            this.getList();
            this.alertService.success('Nota editada com sucesso.');
          },
          error: (err) => this.alertService.error(err.error.message)
        });
      }
    });
  }

  save(): void {
    if (this.formGroup.valid) {
      const note: Note = {
        ...this.formGroup.value,
        attendance: this.attendance
      } as Note;

      this.noteService.create(note).subscribe({
        next: (value) => {
          this.getList();
          this.formGroup.reset();
          this.alertService.success('Nota salva com sucesso.');
        },
        error: (err) => this.alertService.error(err.error.message)
      });
    } else {
      this.alertService.error('Por favor, preencha a descrição.');
    }
  }

  getList(): void {
    this.noteService.getByAttendance(this.attendance._id).subscribe(resp => {
      this.store.dispatch(setNotes({
        notes: resp
      }));
    });
  }
  listNote(){
   const dialogRef = this.dialog.open(ListNoteAttendanceComponent, {
      width: '600px',
      minHeight:'200px'
    });
  }
}
