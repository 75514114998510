import { Component, ElementRef, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TemplateMessageObject, TrendCloudAPIService } from '@trendbuild/trend-cloud-api';
import { AbstractComponent, AlertModalComponent, Attendance, AttendanceService, AttendanceStatusEnum, Channel, ChannelService, ChannelTypeEnum, Contact, ContactEditComponent, ContactService, Department, DepartmentService, MessageService, Team, TeamService, User, WaTemplateComponent, WaTemplateResponse } from 'lib-trend-core';
import { takeUntil } from 'rxjs';
import { getAttendance } from '../../state/actions';
import { AppState } from '../../state/app.state';
import { TemplateMessage } from '../wa-template/wa-template-message/wa-template-message.component';

@Component({
  selector: 'new-conversation',
  templateUrl: './new-conversation.component.html',
  styleUrl: './new-conversation.component.scss'
})
export class ContactNewConversationComponent extends AbstractComponent implements OnInit {
  @ViewChild('btnSendTemplate') btnSendTemplate: ElementRef<HTMLButtonElement>;
  @ViewChild('modalContainer') modalContainer: ElementRef;

  step = 1;
  contacts = new Array<any>();
  selectedContact: Contact = null;
  errorMessage = "";

  channels: Array<Channel>;
  selectedChannel: Channel;

  selectedTemplate: WaTemplateResponse;
  templateMessage: TemplateMessage = {
    midiaHeader: '',
    params: [],
    previewText: '',
    previewHeader: ''
  };

  headerComponent: WaTemplateComponent;
  bodyComponent: WaTemplateComponent;
  footerComponent: WaTemplateComponent;

  execMessage = null;

  previewHeader: any;

  listTeam: Array<Team>;
  listDepartments: Array<Department>;

  selectedDepartment: Department;

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<ContactEditComponent>,
    private dialogAlert: MatDialog,
    public contactService: ContactService,
    public channelService: ChannelService,
    public messageService: MessageService,
    private store: Store<AppState>,
    public attendanceService: AttendanceService,
    public trendCloudAPIService: TrendCloudAPIService,
    private teamService: TeamService,
    private departmentService: DepartmentService,
    @Inject(MAT_DIALOG_DATA) public data: Contact
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.channelService.getList().subscribe(channels => {
      this.channels = channels;
    });

    if (this.isAttendant() || this.isSupervisor()) {
      this.getListTeams();
    } else {
      this.getListDepartments();
    }
  }

  setTemplateMessage(templateMessage: TemplateMessage) {
    this.templateMessage = templateMessage;
    this.previewHeader = templateMessage.previewHeader;
  }

  onContactSelected(contact: any) {
    this.selectedContact = contact;
    this.step = 2;
  }

  selectChannel(channel: Channel) {
    this.errorMessage = "";
    clearTimeout(this.execMessage);

    this.attendanceService.existsContactStatus(this.selectedContact._id, [AttendanceStatusEnum.PENDING,
    AttendanceStatusEnum.IN_PROGRESS, AttendanceStatusEnum.PAUSED, AttendanceStatusEnum.OVERTIME], channel._id).subscribe(alreadyExistsContact => {
      if (alreadyExistsContact) {
        this.dialogAlert.open(AlertModalComponent, {
          data: { title: `Atenção, já existe atendimento para ${this.selectedContact.name} no canal ${channel.name}.`, message: 'Veja nos seus atendimentos ou inicie um novo atendimento por outro canal.' },
        });

        this.modalContainer.nativeElement.scrollTo({
          top: 0,
          behavior: 'smooth'
        });

        this.execMessage = setTimeout(() => {
          this.errorMessage = "";
        }, 6000);

        this.selectedChannel = null;
        return;
      } else {
        if (channel.type === ChannelTypeEnum.CLOUD_API) {
          this.selectedChannel = channel;
        } else {
          this.selectedChannel = channel;
          this.selectedTemplate = null;
          this.sendTemplateMessage();
        }
      }
    });
  }

  selectDepartment(department: Department) {
    this.selectedDepartment = department;
  }

  onSelectTemplate(template: WaTemplateResponse) {
    this.selectedTemplate = template;
    this.step = 4;
  }

  clearValuesToBack(): void {
    this.selectedChannel = null;
    this.selectedContact = null;
    this.selectedDepartment = null;
    this.selectedTemplate = null;
  }

  goToBack(): void {
    this.selectedTemplate = null;
    this.step = 2;
  }

  close() {
    this.dialogRef.close();
  }

  sendTemplateMessage() {

    if (this.selectedChannel.type === ChannelTypeEnum.CLOUD_API) {
      this.btnSendTemplate.nativeElement.disabled = true;
      this.loading = true;
    }

    const templateVariables = this.selectedTemplate?.bodyComponent?.text.match(/{{\d+}}/g) || [];

    if (templateVariables.length > 0 && (!this.templateMessage.params || this.templateMessage.params.length !== templateVariables.length)) {
      this.alertService.error('Por favor, preencha todas as variáveis necessárias.');
      this.loading = false;
      this.btnSendTemplate.nativeElement.disabled = false;
      return;
    }

    const requiresImage = !!this.previewHeader;
    if (requiresImage && !this.templateMessage.midiaHeader) {
      this.alertService.error('Por favor, insira a imagem requerida para o template.');
      this.loading = false;
      this.btnSendTemplate.nativeElement.disabled = false;
      return;
    }

    this.attendanceService.createFromTemplate(
      this.selectedDepartment._id,
      this.selectedChannel._id,
      this.selectedContact._id,
      <TemplateMessageObject>{ ...this.selectedTemplate },
      this.templateMessage.midiaHeader ?? this.templateMessage.previewText,
      this.templateMessage.params
    ).pipe(takeUntil(this.destroy$)).subscribe({
      next: (attendance: Attendance) => {
        this.store.dispatch(getAttendance({ idAttendance: attendance._id }));
      },
      complete: () => {
        this.dialogRef.close();
      },
      error: (err) => {
        this.btnSendTemplate.nativeElement.disabled = false;
        this.loading = false;
        this.alertService.error(err.error.message);
      },
    });
  }

  private getListTeams(): void {
    this.teamService.getList().subscribe({
      next: (list: Array<Team>) => {
        this.listTeam = list
          .filter((team: Team) => {
            const userId = this.getCurrentUserUser()._id;
            const isAgent = team.agents.some((agent: User) => agent._id === userId);
            const isSupervisor = team.supervisors.some((supervisor: User) => supervisor._id === userId);
            return isAgent || isSupervisor;
          })
          .filter((team: Team) => team.department && team.department.removed !== true);
      },
      error: () => this.alertService.error('Ops! Não foi possível trazer os times da sua empresa. Tente novamente mais tarde.'),
    });
  }

  private getListDepartments(): void {
    this.departmentService.getList().subscribe({
      next: (list: Array<Department>) => {
        this.listDepartments = list;
      },
      error: (err: Error) => this.alertService.error(err.message),
    });
  }
}



