import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Attendance, Note, NoteService } from 'lib-trend-core';
@Component({
  selector: 'app-edit-note-attendance',
  templateUrl: './edit-note-attendance.component.html',
  styleUrls:['edit-note-attendance.component.scss']
})
export class EditNoteAttendanceComponent {

  formGroup: FormGroup; 

  constructor(
    public dialogRef: MatDialogRef<EditNoteAttendanceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { note: Note },
    private formBuilder: FormBuilder
  ) {
    this.createForm();
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      description: [this.data.note.description, Validators.required], 
    });
  }

  onNoClick(): void {
    this.dialogRef.close(false); 
  }

  onConfirmClick(): void {
    if (this.formGroup.valid) {
      const editedNote: Note = {
        ...this.data.note,
        description: this.formGroup.value.description, 
      };
      this.dialogRef.close(editedNote); 
    }
  }

}
