import { createSelector } from "@ngrx/store";
import { AppState } from "./app.state";

export const getAttendanceFeatureState = (state: AppState) => state.attendanceState;

// app state
export const companySelector = createSelector(
  getAttendanceFeatureState,
  (state) => state.company
);

export const userSelector = createSelector(
  getAttendanceFeatureState,
  (state) => state.user
);

// attendance
export const isLoadingSelector = createSelector(
  getAttendanceFeatureState,
  (state) => state.isLoading
);

export const attendancesSelector = createSelector(
  getAttendanceFeatureState,
  (state) => state.attendances
);

export const attendanceSelector = createSelector(
  getAttendanceFeatureState,
  (state) => state.attendance
);

export const notesSelector = createSelector(
  getAttendanceFeatureState,
  (state) => state.notes
);

export const messagesSelector = createSelector(
  getAttendanceFeatureState,
  (state) => state.messages
);

export const pagerParamsSelector = createSelector(
  getAttendanceFeatureState,
  (state) => state.pagerParams
);

export const errorSelector = createSelector(
  getAttendanceFeatureState,
  (state) => state.error
);

export const openCloseSidebarRightSelector = createSelector(
  getAttendanceFeatureState,
  (state) => state.sidebarRight
);

export const openClosecontactInfoSelector = createSelector(
  getAttendanceFeatureState,
  (state) => state.contactInfo
)

export const tagsSelector = createSelector(
  getAttendanceFeatureState,
  (state) => state.tags
)

export const countsSelector = createSelector(
  getAttendanceFeatureState,
  (state) => state.counts
)