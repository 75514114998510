<div class="mr-[20px] bg-white p-2 rounded-lg border border-[#bdbdbd] w-[300px] h-[87vh] overflow-y-auto shadow-global"
  [@slideInOut]="sidebarState">
  <contact-info-component></contact-info-component>
  <service-note-attendance></service-note-attendance>
  <h4 class="font-semibold mb-1">Etiquetas</h4>
  <service-tag-attendance [categoryTag]="typeProtocol"></service-tag-attendance>
  <service-tag-attendance [categoryTag]="typeInterest"></service-tag-attendance>
  <service-tag-attendance [categoryTag]="typeContact"></service-tag-attendance>
  <h4 class="font-semibold mb-1">Campos personalizados</h4>
  <custom-field-attendance></custom-field-attendance>
</div>