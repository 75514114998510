<div class="note-main">
  <h4 class="label-note">Anotações Internas</h4>
  <div cellspacing="0">
    <form [formGroup]="formGroup" (ngSubmit)="save()" class="input-wrapper">
      <input class="input-field" maxlength="500" formControlName="description" placeholder="digitar...">
      <button type="submit" class="input-icon-button">
        <svg class="input-icon" width="24" height="24" viewBox="0 0 24 24" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.51 6.03L11.02 9.25L3.5 8.25L3.51 6.03ZM11.01 14.75L3.5 17.97V15.75L11.01 14.75ZM1.51 3L1.5 10L16.5 12L1.5 14L1.51 21L22.5 12L1.51 3Z"
            fill="#667085" />
        </svg>
      </button>
    </form>
    <tr class="card-note-container" role="row" *ngFor="let item of (notes$ | async)?.slice(-1)">
      <td class="note-card">
        {{item.description}}
        <span class="data-time-note">{{ item.updatedAt | date:'dd/MM/yyyy HH:mm' }}</span>
      </td>
      <td>
        <div [matMenuTriggerFor]="menu">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.99984 10.834C10.4601 10.834 10.8332 10.4609 10.8332 10.0007C10.8332 9.54041 10.4601 9.16732 9.99984 9.16732C9.5396 9.16732 9.1665 9.54041 9.1665 10.0007C9.1665 10.4609 9.5396 10.834 9.99984 10.834Z"
              stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M9.99984 5.00065C10.4601 5.00065 10.8332 4.62755 10.8332 4.16732C10.8332 3.70708 10.4601 3.33398 9.99984 3.33398C9.5396 3.33398 9.1665 3.70708 9.1665 4.16732C9.1665 4.62755 9.5396 5.00065 9.99984 5.00065Z"
              stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M9.99984 16.6673C10.4601 16.6673 10.8332 16.2942 10.8332 15.834C10.8332 15.3737 10.4601 15.0007 9.99984 15.0007C9.5396 15.0007 9.1665 15.3737 9.1665 15.834C9.1665 16.2942 9.5396 16.6673 9.99984 16.6673Z"
              stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
        <mat-menu #menu="matMenu">
          <button mat-menu-item color="primary" (click)="delete(item)">
            <span>Remover</span>
          </button>
          <button mat-menu-item color="primary" (click)="edit(item)">
            <span>Editar</span>
          </button>
        </mat-menu>
      </td>
    </tr>

  </div>
  <button class="viwer-note-button" (click)="listNote()">Ver notas</button>
</div>