import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-trend-core';
import { WaTemplateMessageComponent } from './wa-template-message/wa-template-message.component';
import { WaTemplateComponent } from './wa-template/wa-template.component';



@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    WaTemplateComponent,
    WaTemplateMessageComponent
  ],
  exports: [
    WaTemplateComponent,
    WaTemplateMessageComponent
  ],
  providers: [],
})
export class WaTemplateModule { }
