import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AttendancePanelComponent } from './attendance-panel.component';

// /user
const routes: Routes = [
  { path: '', component: AttendancePanelComponent, },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AttendancePanelRoutingModule { }
