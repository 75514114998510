import { createReducer, on } from "@ngrx/store";
import { Attendance, Message, Note, Pager, Tag } from "lib-trend-core";
import { cloneDeep } from "lodash";
import * as AttendanceActions from "./actions";
import { AttendanceState, CountState } from "./app.state";

// initial global state
export const initialState: AttendanceState = {
  isLoading: false,
  company: undefined,
  user: undefined,
  attendance: undefined,
  attendances: new Pager<Attendance>(),
  notes: new Array<Note>(),
  tags: new Array<Tag>(),
  messages: new Array<Message>(),
  error: null,
  pagerParams: undefined,
  sidebarRight: false,
  contactInfo: false,
  counts: new Array<CountState>(),
}

//reducers
export const attendanceReducers = createReducer(
  initialState,
  on(AttendanceActions.clearFullState, (state) => ({ ...cloneDeep(initialState) })),
  on(AttendanceActions.getAttendances, (state) => ({ ...state, isLoading: true })),
  on(AttendanceActions.getAttendancesSuccess, (state, action) => ({ ...state, isLoading: false, attendances: action.attendances })),

  on(AttendanceActions.getCounts, (state, action) => ({ ...state, isLoading: true })),

  on(AttendanceActions.getMessages, (state) => ({ ...state, isLoading: true })),
  on(AttendanceActions.getNotes, (state) => ({ ...state, isLoading: true })),
  on(AttendanceActions.getTags, (state) => ({ ...state, isLoading: true })),

  on(AttendanceActions.setAttendance, (state, action) => ({ ...state, isLoading: false, attendance: action.attendance })),
  on(AttendanceActions.setTags, (state, action) => ({ ...state, isLoading: false, tags: action.tags })),
  on(AttendanceActions.setNotes, (state, action) => ({ ...state, isLoading: false, notes: action.notes })),
  on(AttendanceActions.setMessages, (state, action) => ({ ...state, isLoading: false, messages: action.messages })),
  on(AttendanceActions.setPagerParams, (state, action) => ({ ...state, isLoading: false, pagerParams: action.pagerParams })),
  on(AttendanceActions.openCloseSidebarRight, (state, action) => ({ ...state, isLoading: false, sidebarRight: action.sidebarRight })),
  on(AttendanceActions.openCloseContactInfo, (state, action) => ({ ...state, isLoading: false, contactInfo: action.contactInfo })),

  on(AttendanceActions.setCounts, (state, action) => ({ ...state, isLoading: false, counts: action.counts })),

  // init
  on(AttendanceActions.setLoadInit, (state, action) => ({ ...state, user: action.user, company: action.company })),

  // failure
  on(AttendanceActions.setFailure, (state, action) => ({ ...state, error: action.error })),
);
