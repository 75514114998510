import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-trend-core';
import { TagAttendanceComponent } from './tag-attendance.component';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    TagAttendanceComponent,
  ],
  exports: [
    TagAttendanceComponent,
  ],
  providers: [

  ]
})

export class TagAttendanceModule { }
