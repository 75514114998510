import { Component, Inject, Injector, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractComponent, Attendance, AttendanceService, Department, DepartmentService, Team, TeamService, User } from 'lib-trend-core';

@Component({
  selector: 'assign-user-attendance',
  templateUrl: './assigned-user.component.html',
  styleUrls: ['./assigned-user.component.scss']
})
export class AssignedUserComponent extends AbstractComponent implements OnInit {

  @ViewChild('transferModal') transferModal: TemplateRef<any>;

  showTransferModalOnly = false;

  userList: User[] = [];
  selectedUser: User;
  departments: Department[] = [];
  users: User[] = [];
  searchTerm = '';
  isOperatorSelected: boolean = false;
  isSelectDepartment: boolean;

  constructor(
    injector: Injector,
    public attendanceService: AttendanceService,
    public departmentService: DepartmentService,
    public teamService: TeamService,
    public dialogRef: MatDialogRef<AssignedUserComponent>,
    @Inject(MAT_DIALOG_DATA) public attendance: Attendance,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.loadDepartments();
    this.setupForm();

    this.formGroup.get('department')?.valueChanges.subscribe(idDepartment => {
      if (idDepartment) {
        this.loadUsersByDepartment(idDepartment);
      } else {
        this.users = [];
      }
    });

    if (this.attendance?.department) {
      this.formGroup.patchValue({
        department: this.attendance.department._id,
      });
      this.loadUsersByDepartment(this.attendance.department._id);
    }
  }

  setupForm() {
    this.formGroup = this.formBuilder.group({
      department: [null, [Validators.required]],
      user: [null],
    });
  }

  private loadDepartments() {
    this.departmentService.getList().subscribe({
      next: (value) => {
        this.departments = value;
      },
    });
  }

  private loadUsersByDepartment(idDepartment: string) {
    if (!idDepartment) return;
    this.teamService.getByDepartment(idDepartment).subscribe({
      next: (teams: Team[]) => {
        if (teams.length > 0) {
          this.users = teams[0].agents;
        } else {
          this.users = [];
        }
      },
      error: (err) => this.alertService.error(err.error.message),
    });
  }

  confirmTransfer() {
    const idAttendance = this.attendance._id;
    const idDeparment = this.formGroup.get('department').value;
    const idUser = this.formGroup.get('user').value ?? null;
    this.attendanceService.transferAttendance(idAttendance, idDeparment, idUser).subscribe({
      next: (attendance: Attendance) => {
        this.alertService.success('Transferência realizada com sucesso.');
        this.dialogRef.close(attendance);
      },
    });
  }

  clearSelection() {
    this.formGroup.patchValue({ user: null });
    this.isOperatorSelected = false;
  }

  toggleSlide(event: any): void {
    const isChecked = event.checked;
    this.isOperatorSelected = isChecked;

    if (!isChecked) {
      this.clearSelection();
    }
  }

}
