<form class="tag-main">
  <h4 class="label-attendance mb-1">{{getTitle()}}</h4>
  <div class="main-tag-selector">
    <input class="input-field-tag" *ngIf="showAutocomplete" #tagInput [matAutocomplete]="auto"
      placeholder="Pesquisar..." />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let tag of tagsByCategory" [value]="tag">
        <span class="shadow-2xl rounded-[5px] px-3 h-6 font-semibold"
          [ngStyle]="{'background-color': tag.bgColor, 'color': tag.fontColor ?? '#FFF'}">
          {{tag.title}}
        </span>
      </mat-option>
    </mat-autocomplete>
    <button class="button-add" (click)="toggleAutocomplete()">
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.8337 6.83268H6.83366V11.8327H5.16699V6.83268H0.166992V5.16602H5.16699V0.166016H6.83366V5.16602H11.8337V6.83268Z"
          fill="#4213F6" />
      </svg>
    </button>
  </div>
  <div class="chip-list">
    <div class="chip-container">
      <div class="chip shadow-md" [ngStyle]="{'background-color': tag.bgColor}" *ngFor="let tag of getFilteredList()">
        <span class="px-3 h-6 font-semibold"
              [ngStyle]="{'background-color': tag.bgColor, 'color': tag.fontColor ?? '#FFF'}">
          {{tag.title}}
        </span>
        <button class="chip-remove" (click)="remove(tag)">
          <span class="icon">x</span>
        </button>
      </div>
    </div>
  </div>
</form>