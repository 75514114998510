import { Component, ElementRef, EventEmitter, Inject, Injector, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AbstractComponent, AlertModalComponent, AttendanceService, Channel, ChannelService, Contact, ContactEditComponent, ContactService, FirebaseService, MessageService, UploadTypeEnum, UtilHelper, WaTemplateResponse } from 'lib-trend-core';
import { BehaviorSubject } from 'rxjs';

export interface TemplateMessage {
  previewText: string;
  previewHeader: string;
  midiaHeader: string;
  params: []
}

@Component({
  selector: 'wa-template-message',
  templateUrl: './wa-template-message.component.html',
  styleUrls: ['./wa-template-message.component.scss'],
})
export class WaTemplateMessageComponent extends AbstractComponent implements OnInit {
  @Input() templateMessage: TemplateMessage;
  @Output() midiaHeaderChange = new EventEmitter<string>();



  @ViewChild('transferModal') transferModal: TemplateRef<any>;
  @ViewChild('inputImagefile', { static: false }) inputImagefile: ElementRef<HTMLInputElement>;
  @ViewChild('inputVideofile', { static: false }) inputVideofile: ElementRef<HTMLInputElement>;
  @ViewChild('inputDocfile', { static: false }) inputDocfile: ElementRef<HTMLInputElement>;
  @ViewChild('inputAudiofile', { static: false }) inputAudiofile: ElementRef<HTMLInputElement>;

  private loadingSpinnerSubject = new BehaviorSubject<boolean>(false);
  loadingSpinnerTemplate$ = this.loadingSpinnerSubject.asObservable();

  file: File;
  fileZero: any;
  fileZeroSrc: string;

  uploadHeader: boolean = false;

  selectedTemplate: any;
  selectedChannel: Channel;
  selectedContact: Contact;

  @Input() set template(template: WaTemplateResponse) {
    this.selectedTemplate = template;
    this.extractTemplateVariables();
  }

  @Input() set channel(channel: Channel) {
    this.selectedChannel = channel;
  }

  @Input() set contact(contact: Contact) {
    this.selectedContact = contact;
  }

  @Output() templateParams = new EventEmitter();

  override templateVariableList = [];

  previewText: string;
  previewFooter: string;
  previewHeader: any;

  midiaHeader: any;

  form: FormGroup;

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<ContactEditComponent>,
    public contactService: ContactService,
    public channelService: ChannelService,
    public messageService: MessageService,
    private firebaseService: FirebaseService,
    public attendanceService: AttendanceService,
    private modalAlert: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: Contact,

  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.setupForm();
  }

  private setupForm(): void {
    this.form = new FormGroup({
      image: new FormControl('')
    });

    if (this.templateVariableList.length > 0) {
      this.templateVariableList.forEach((variable, index) => {
        this.form.addControl(`var${index + 1}`, new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(30)]));

        this.form.get(`var${index + 1}`).valueChanges.subscribe(data => {
          this.updatePreviewText();
        });

        this.form.valueChanges.subscribe(value => {
          const params = this.templateVariableList.map((_, idx) => this.form.get(`var${idx + 1}`).value);
          this.templateParams.emit({
            previewText: this.previewText,
            previewHeader: this.previewHeader,
            midiaHeader: this.midiaHeader,
            params: params
          });
        });
      });
    } else {
      this.updatePreviewText();
      this.templateParams.emit({
        previewText: this.previewText,
        previewHeader: this.previewHeader,
        midiaHeader: this.midiaHeader,
        params: []
      });
    }
  }

  private updatePreviewText(): void {
    let preview = this.selectedTemplate?.bodyComponent.text ?? '';

    this.templateVariableList.forEach((variable, idx) => {
      preview = preview.replace(`{{${idx + 1}}}`, this.form.get(`var${idx + 1}`).value);
    });

    this.previewText = preview;
  }

  extractTemplateVariables(): void {
    if (!this.selectedTemplate) return;

    this.previewText = this.selectedTemplate.bodyComponent.text;
    this.previewFooter = this.selectedTemplate.footerComponent?.text ?? '';
    this.previewHeader = this.selectedTemplate.headerComponent?.example?.header_handle.map((url) => url).toString();
    this.templateVariableList = [];
    for (let i = 1; i <= 5; i++) {
      if (this.previewText.includes(`{{${i}}}`)) {
        this.templateVariableList.push(`{{${i}}}`);
      }
    }

    this.setupForm();
  }

  attachmentTemplate() {
    const midia = this.formGroup.get('attachment')?.value;

    if (midia) {
      const mediaType = this.helperExtension(midia);

      switch (mediaType) {
        case 'image':
          return midia;
        case 'audio':
          return UtilHelper.on_audio;
        case 'video':
          return UtilHelper.on_video;
        case 'document':
          return UtilHelper.on_pdf;
        default:
          return UtilHelper.on_file;
      }
    } else {
      return UtilHelper.no_image_upload;
    }
  }

  private helperExtension(midia: string): 'image' | 'audio' | 'video' | 'document' | 'text' {
    const decodedUrl = decodeURIComponent(midia);

    const fileNameWithQuery = decodedUrl.split('/').pop() || '';
    const fileName = fileNameWithQuery.split('?')[0];

    const extension = fileName.split('.').pop()?.toLowerCase();

    const regex = /_(\d+)/;
    const nameWithoutPart = extension.replace(regex, '');

    switch (nameWithoutPart.trim()) {
      case 'mp4':
        return 'video';
      case 'pdf':
        return 'document';
      case 'mp3':
        return 'audio';
      case 'png':
        return 'image';
      case 'jpg':
        return 'image';
      case 'jpeg':
        return 'image'
      default:
        return 'text';
    }
  }

  onSelectImagem(event: Event): void {
    const target = event.target as HTMLInputElement;
    const files: FileList = target.files;
    this.file = files[0];

    if (this.file) {
      const fileType = this.file.type;
      const fileSize = this.file.size;

      const fileTypeLimit = UtilHelper.getFileTypeLimit(fileType);

      if (fileTypeLimit) {
        if (fileSize > fileTypeLimit.maxSize) {
          this.openModalAlert(fileTypeLimit.alertTitle, fileTypeLimit.alertMessage, target);
          return;
        }
      } else {
        this.openModalAlert('Tipo de arquivo não suportado', '', target);
        return;
      }

      let reader = new FileReader();
      reader.onload = async (file) => {
        if (this.file) {
          this.loadingSpinnerSubject.next(true);
          this.uploadHeader = true;
          const contents = file.target as FileReader;
          const base64 = UtilHelper.arrayBufferToBase64(contents.result);
          const dateMilisecond = new Date().getTime();
          const filename: string = dateMilisecond.toString();

          this.firebaseService.uploadFile(this.file, filename, UploadTypeEnum.MESSAGE).then((snapshot: { url: string }) => {
            this.loadingSpinnerSubject.next(false);
            this.midiaHeader = snapshot.url;

            this.templateMessage.midiaHeader = snapshot.url;
            this.midiaHeaderChange.emit(this.templateMessage.midiaHeader);

          }).catch((error) => {
            this.loadingSpinnerSubject.next(false);
            console.error('Erro no upload da imagem:', error);
          });
        }
      };
      reader.readAsArrayBuffer(this.file);
    }
  }
  
  updateMidiaHeader(newMidiaHeader: string) {
    this.templateMessage.midiaHeader = newMidiaHeader;
    this.midiaHeaderChange.emit(this.templateMessage.midiaHeader);
  }

  private openModalAlert(title: string, message: string, inputElement: HTMLInputElement): void {
    const dialogRefAlert = this.modalAlert.open(AlertModalComponent, {
      width: '500px',
      data: { title: title, message: message },
    });
    dialogRefAlert.afterClosed().subscribe(() => {
      inputElement.value = '';
    });
  }

  selectVariable(varNumber: number, variableType: string): void {
    const currentHour = new Date().getHours();

    const valueMap: Record<string, () => string> = {
      greeting: () => {
        if (currentHour < 12) return 'Bom dia';
        if (currentHour < 18) return 'Boa tarde';
        return 'Boa noite';
      },
      contactName: () => this.selectedContact.name,
      operatorName: () => this.getCurrentUserUser().name,
      departmentName: () => this.getCurrentUserUser().company.name,
      linkChannel: () => this.selectedChannel.name,
      protocol: () => this.selectedChannel._id,
    };

    const getValue = valueMap[variableType];
    const value = getValue ? getValue() : '';

    this.form.get(`var${varNumber}`).setValue(value);
  }
}