import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AbstractComponent, ConfirmationComponent, Contact, ContactEditComponent, ContactService, Pager } from 'lib-trend-core';
import { Observable, Subject, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';

@Component({
  selector: 'contact-list-component',
  templateUrl: 'contact-list.component.html',
  styleUrl: 'contact-list.component.scss'
})
export class ContactListComponent extends AbstractComponent implements OnInit {

  pager: Pager<Contact> = new Pager<Contact>({ perPage: 10 });
  listObservable: Observable<Pager<Contact>>;
  private termoDaBusca: Subject<string> = new Subject<string>();

  @Output('onSelect') onSelect = new EventEmitter();

  constructor(
    injector: Injector,
    public dialog: MatDialog,
    public contactService: ContactService,
  ) {
    super(injector);
    this.createForm();
  }

  ngOnInit(): void {
    this.formGroup.controls['type'].valueChanges.subscribe(value => {
      this.getList();
    });
    this.getList();
  }

  select(item){
    this.onSelect.emit(item);
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getList();
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      type: [null],
    });
  }

  setupObservableSearch() {
    this.listObservable = this.termoDaBusca
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(term => {
        return this.contactService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams);
      }));
    this.listObservable.subscribe((pager: Pager<Contact>) => {
      this.pager = pager;
    });
  }

  search(termo: string) {
    this.pager.page = 1;
    this.searchString = termo;
    this.termoDaBusca.next(termo);
  }


  getList() {
    this.searchParams = {
      company: this.getIDCurrentCompany(),
    };
    const type = this.formGroup.get('type').value;
    if (type) {
      this.searchParams['type'] = type;
    }
    this.contactService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams).subscribe({
      next: (value) => {
        this.pager = value;
        this.setupObservableSearch();
      },
      error: (err) => this.alertService.error(err.error.message),
    });
  }

  add(): void {
    const dialogRef = this.dialog.open(ContactEditComponent, {
      width: '600px'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getList();
    });
  }

  edit(item: Contact): void {
    const dialogRef = this.dialog.open(ContactEditComponent, {
      width: '600px',
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getList();
    });
  }

  delete(item: Contact): void {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (Boolean(result) === true) {
        this.contactService.delete(item._id).subscribe({
          next: () => {
            this.getList();
            this.alertService.success('Contato excluído com sucesso.');
          },
          error: err => this.alertService.error(err.error.message)
        })
      }
    });
  }


}
