import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-trend-core';
import { CustomFieldAttendanceComponent } from './custom-field-attendance.component';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    CustomFieldAttendanceComponent,
  ],
  exports: [
    CustomFieldAttendanceComponent,
  ],
  providers: [
  ]
})

export class CustomFieldAttendanceModule { }
