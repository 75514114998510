import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractComponent } from 'lib-trend-core';
import { clearFullState } from './state/actions';
import { AppState } from './state/app.state';
import { Effects } from './state/effects';

@Component({
  selector: 'attendance-panel-component',
  templateUrl: 'attendance-panel.component.html'
})
export class AttendancePanelComponent extends AbstractComponent implements OnInit, OnDestroy {

  constructor(
    public injector: Injector,
    private store: Store<AppState>,
    private effects: Effects,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.store.dispatch(clearFullState({ clear: true }));
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.effects.destroyEffects();
  }

}
