import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'lib-trend-core';
import { AssignedUserComponent } from './assigned-user.component';

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    AssignedUserComponent,
  ],
  exports: [
    AssignedUserComponent,
  ],
  providers: [],
})

export class AssignedUserModule { }
